import { type Dayjs } from "dayjs";
import { action, makeObservable, observable, runInAction } from "mobx";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Lazy } from "models/lazy";
import { useSession } from "models/session";
import { ProjectRaw, ScenarioRaw, updateProject } from "services/back/project";

import { Fact } from "./fact/fact";
import { Fields } from "./filed/field";
import { LicenseRegions } from "./licenseRegion/licenseRegions";
import { Participants } from "./participants/partisipants";
import { ProducingObjects } from "./producingObject/producingObjects";
import { Stratums } from "./stratum/stratums";

class Project {
  fact: Fact;
  #producingObjects = new Lazy(() => new ProducingObjects(this.id));
  public readonly stratums: Stratums;
  public readonly fields: Fields;
  public readonly licenseRegions: LicenseRegions;
  public isMixedSelling: boolean;

  public readonly title: string;
  public readonly fieldTitle: string;
  public readonly description: string;
  public readonly id: number;
  scenarios: ScenarioRaw[];
  public readonly startYear: number; //год начала инвестиционной деятельности
  public readonly lastForecastYear: number;
  public readonly actualStateDate: Dayjs; // это называется "дата оценки", момент после которого фактические данные не учитываются
  public readonly oilWays: number[];
  public readonly isAssetAlgoNdd: ScenarioRaw["isAssetAlgoNdd"];
  public readonly isInfrastructure: ScenarioRaw["isInfrastructure"];
  public readonly isLossTransfer: ScenarioRaw["isLossTransfer"];
  public readonly isMajorProject: ScenarioRaw["isMajorProject"];
  public readonly preferences?: ProjectRaw["preferences"];
  public readonly createdBy: number | null;
  public readonly createdAt: string | null;
  public readonly wellsBaseNumber: number | null;
  public longProcesses: Record<string, { progress: number; going: boolean; amount: number }> = {};
  #participants = new Lazy(() => new Participants(this.id));

  constructor(data: ProjectRaw, isMixedSelling: boolean) {
    this.title = data.title;
    this.fieldTitle = data.fieldTitle;
    this.description = data.description;
    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
    this.id = data.id;
    this.scenarios = data.scenarios;
    this.startYear = data.investStartYear;
    this.lastForecastYear = data.stop;
    this.actualStateDate = data.actualStateDate;
    this.oilWays = data.catalogDirectionIds;
    this.isAssetAlgoNdd = data.isAssetAlgoNdd;
    this.isInfrastructure = data.isInfrastructure;
    this.isLossTransfer = data.isLossTransfer;
    this.isMajorProject = data.isMajorProject;
    this.preferences = data.preferences;
    this.wellsBaseNumber = data.wellsBaseNumber;

    this.isMixedSelling = isMixedSelling;
    this.stratums = new Stratums(this, data.stratumIds);
    //это id месторождения им.Некрасова, пока захардкодил тут
    this.fields = new Fields([34]);
    this.licenseRegions = new LicenseRegions(data.lz, this);

    makeObservable(this, {
      longProcesses: observable,
      evalSequentialRequests: action,
      applyFromFormData: action,
    });
    // has to be initialized at the end for getters to work
    this.fact = Fact.fromRawData(data.scenarios, this);
  }

  static fromRawData(data: ProjectRaw): Project {
    return new Project(data, false);
  }

  async applyFromFormData(data: Partial<ProjectRaw>) {
    const response = await updateProject(this.id, data);
    Object.assign(this, response);
    return response;
  }

  evalSequentialRequests<T>(requests: Promise<T>[], title: string): Promise<T[]> {
    runInAction(() => {
      this.longProcesses[title] = observable({
        progress: 0,
        going: true,
        amount: 0,
      });
    });
    const state = this.longProcesses[title];

    state.amount = requests.length;
    requests.forEach((req) => req.finally(() => (state.progress += 1)));

    const whole = Promise.all(requests);
    whole.finally(() => (state.going = false));

    return whole;
  }

  get earlyestPlanYear(): number {
    return this.fact.lastDescribedYear + 1;
  }

  get isLoading(): boolean {
    return this.participants.isLoading;
  }

  get participants(): Participants {
    return this.#participants.value;
  }

  get producingObjects(): ProducingObjects {
    return this.#producingObjects.value;
  }
}

const useProject: () => Project | null | undefined = () => {
  const { project } = useMainRouterParams();
  const projects = useSession().projects;
  if (project === undefined) {
    return null;
  }
  return projects.at(project);
};

export { Project, useProject };
