import { observer } from "mobx-react";

import { useTreeContext } from "./model/context";
import { KEY_JOIN_SYMBOL } from "./model/types";

import cn from "./treeSelectionDebug.module.less";

const TreeSelectionDebug = observer(() => {
  const tree = useTreeContext();
  return (
    <ul className={cn.layout}>
      {Array.from(tree.selectManager.selected.keys(), (key) => {
        const item = tree.at(parseInt(key?.split(KEY_JOIN_SYMBOL).at(-1) ?? ""));
        return (
          <li key={key}>
            {key}: {typeof item?.title === "function" ? item?.title(item) : item?.title}
          </li>
        );
      })}
    </ul>
  );
});

export { TreeSelectionDebug };
