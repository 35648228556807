import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { ExternalSourceCalculate } from "services/back/infrastructure/calculate";

type FormatExternalSourceType<T> = { uuid: string; title: string; params: T[] };

type ExternalSourceType = { date: Dayjs } & ExternalSourceCalculate;

type DRow = {
  date: string;
  title: string;
  fluidRateT: number;
  oilRateT: number;
  waterRateT: number;
  injRateT: number;
};

class ExternalSource extends TableNode<DRow> {
  asDRow = (): DRow => ({
    ...this.data,
    title: this.data.title,
    date: dayjs(this.data.date).format("MM.YYYY"),
  });
  data: ExternalSourceType;
  constructor(private parent: ExternalSources, data: ExternalSourceType) {
    super(parent);

    this.data = data;
  }
}

class ExternalSources extends TableNode<DRow, ExternalSource> {
  asDRow = () =>
    ({
      title: this.name,
    } as DRow);
  constructor(public parent: ExternalSourcesModel, private name: string, private params: ExternalSourceType[]) {
    super(parent, { isExpandedChildren: true });

    this.initChildren();
  }

  initChildren() {
    const array = this.params.map((el) => new ExternalSource(this, el));
    this.childrenStore = new ChildrenStoreArray(this, array);
  }
}
class ExternalSourcesModel extends TableNode<DRow, ExternalSources> {
  constructor(public infrastructure: Infrastructure) {
    super();

    this.initChildren();
  }

  initChildren() {
    const calculations = this.infrastructure.calculateStore.hydraulicDataCollection;
    const arrayMap = new Map<string, FormatExternalSourceType<ExternalSourceType>>();
    for (const calculation of calculations || []) {
      if (!calculation.externalSources.length) {
        continue;
      }
      const { externalSources, date } = calculation;
      ExternalSourcesModel.formatData(externalSources ?? [], arrayMap, date);
    }
    const resultArray = [...arrayMap.values()];
    this.childrenStore = new ChildrenStoreArray(
      this,
      resultArray.map((el) => new ExternalSources(this, el.title, el.params))
    );
  }

  static formatData = (
    data: ExternalSourceCalculate[],
    result: Map<string, FormatExternalSourceType<ExternalSourceType>>,
    date: Dayjs
  ) => {
    for (const item of data) {
      const newItem = { ...item, date };
      if (result.has(item.nodeUuid)) {
        result.get(item.nodeUuid)?.params.push(newItem);
      } else {
        result.set(item.nodeUuid, {
          uuid: item.uuid,
          title: item.title,
          params: [newItem],
        });
      }
    }
  };
}

export { type DRow as DRowExternalSources, ExternalSourcesModel };
