import { action, computed, makeObservable, observable } from "mobx";

import { SearchParamsStorage } from "elements/useSearchParamsStorage";
import { Project } from "models/project/project";

import { type CalculateSettings } from "../services";

type FieldData<T = number> = {
  value: T | undefined;
  options: SelectorOption<T>[];
  disabled: boolean;
  error?: "recalculate";
};

type SelectorOption<T = number> = {
  label: string;
  value: T;
};

type EditableSettings = Omit<CalculateSettings, "discount">;

type Settings = {
  [k in keyof EditableSettings]: CalculateSettings[k] | undefined;
};

class FactorAnalysisSettingsModel {
  #scenariosSelector: SelectorOption[];
  #uscSelector: SelectorOption[];

  public readonly badScenarios = observable.set<number>();

  private settings: Settings;

  constructor(private readonly project: Project, private readonly storage: SearchParamsStorage) {
    makeObservable<FactorAnalysisSettingsModel, "settings">(this, {
      settings: observable,

      basisScenarioItem: computed,
      comparedScenarioItem: computed,
      basisUscItem: computed,
      comparedUscItem: computed,
      setItem: action,

      canReset: computed,
      isValid: computed,
    });

    this.#scenariosSelector = project.fact.forecasts.selector!;
    this.#uscSelector = project.fact.ecyStore.selector!;

    this.settings = this.init();
  }

  private init(empty: boolean = false): Settings {
    if (!empty) {
      const saved = this.storage.getItem<EditableSettings>(`fa-${this.project.id}`);
      if (saved) {
        return saved;
      }
    }
    return {
      basisScenario: undefined,
      comparedScenario: undefined,
      basisUsc: undefined,
      comparedUsc: undefined,
    };
  }

  public save = (): void => {
    if (this.calculationSettings === null) {
      return;
    }
    const { discount, ...toSave } = this.calculationSettings;
    this.storage.setItem<EditableSettings>(`fa-${this.project.id}`, toSave);
  };

  public clear = (): void => {
    this.storage.removeItem(`fa-${this.project.id}`);
    this.settings = this.init(true);
  };

  public get canReset(): boolean {
    for (const v of Object.values(this.settings)) {
      if (v !== undefined) {
        return true;
      }
    }
    return false;
  }

  public get isValid(): boolean {
    const { basisScenario, basisUsc, comparedScenario, comparedUsc } = this.settings;
    if ([basisScenario, basisUsc, comparedScenario, comparedUsc].includes(undefined)) {
      return false;
    }
    if (basisScenario === comparedScenario) {
      return false;
    }
    if (basisUsc === comparedUsc) {
      return false;
    }
    return true;
  }

  public getItem(name: keyof Settings): FieldData<any> {
    return this[`${name}Item`];
  }

  public setItem(name: keyof Settings, value: number): void {
    this.settings[name] = value;
    if (name === "basisScenario" && this.#scenariosSelector.length === 2) {
      this.settings.comparedScenario = this.#scenariosSelector.find((v) => v.value !== value)!.value;
    }
    if (name === "basisUsc" && this.#uscSelector.length === 2) {
      this.settings.comparedUsc = this.#uscSelector.find((v) => v.value !== value)!.value;
    }
  }

  public get calculationSettings(): CalculateSettings | null {
    if (!this.isValid) {
      return null;
    }
    const { basisScenario, comparedScenario, basisUsc, comparedUsc } = this.settings as EditableSettings;
    const discount = this.project.fact.ecyStore.at(basisUsc)?.discount;
    return {
      basisScenario,
      comparedScenario,
      basisUsc,
      comparedUsc,
      discount,
    };
  }

  public get basisScenarioItem(): FieldData<number> {
    const value = this.settings.basisScenario;
    return {
      value,
      options: this.#scenariosSelector,
      disabled: false,
      error: value !== undefined && this.badScenarios.has(value) ? "recalculate" : undefined,
    };
  }

  public get comparedScenarioItem(): FieldData<number> {
    const value = this.settings.comparedScenario;
    return {
      value,
      options: this.#scenariosSelector.filter(({ value }) => value !== this.settings.basisScenario),
      disabled: this.settings.basisScenario === undefined,
      error: value !== undefined && this.badScenarios.has(value) ? "recalculate" : undefined,
    };
  }

  public get basisUscItem(): FieldData<number> {
    return {
      value: this.settings.basisUsc,
      options: this.#uscSelector,
      disabled: false,
    };
  }

  public get comparedUscItem(): FieldData<number> {
    return {
      value: this.settings.comparedUsc,
      options: this.#uscSelector.filter(({ value }) => value !== this.settings.basisUsc),
      disabled: this.settings.basisUsc === undefined,
    };
  }
}

export { FactorAnalysisSettingsModel };
