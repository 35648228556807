import { FC } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";

import { useTreeContext } from "./model/context";

import cn from "./nestingSelector.module.less";

const NestingSelector: FC = observer(() => {
  const treeContext = useTreeContext();

  return (
    <Select
      value={treeContext.nestingSelection as any}
      className={cn.selector}
      mode="multiple"
      maxTagCount={1}
      optionFilterProp="label"
      placeholder="Признак группировки"
      onChange={treeContext.onNestingChange}
      options={treeContext.nestingOptions}
    />
  );
});

export { NestingSelector };
