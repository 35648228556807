import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { type Required, type Tree } from "./tree";

const TreeContext = createContext<Tree<any> | null>(null);

const TreeContextProvider = <T extends Required>({ value, children }: PropsWithChildren<{ value: Tree<T> }>) => {
  return <TreeContext.Provider value={value}>{children}</TreeContext.Provider>;
};

const useTreeContext = <T extends Required>(): Tree<T> => {
  const tree = useContext(TreeContext);
  console.assert(tree !== null, "useTreeContext out of context");
  return tree! as Tree<T>;
};

const useSecureTreeContext = (): Tree<Required> | null => useContext<Tree<Required> | null>(TreeContext);

export { TreeContextProvider, useSecureTreeContext, useTreeContext };
