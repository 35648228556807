import { useParamExternalSourcesTableStore } from "./useParamExternalSourcesTableStore/useParamExternalSourcesTableStore";
import { useParamPipesTableStore } from "./useParamPipesTableStore/useParamPipesTableStore";
import { useParamStationsTableStore } from "./useParamStationsTableStore/useParamStationsTableStore";
import { useParamDrainSourcesTableStore } from "./useParamDrainSourcesTableStore";
import { useParamMinesTableStore } from "./useParamMinesTableStore";

const useParamsTableByMode = (mode: string) => {
  const { columns: prodColumns, store: oilStore } = useParamPipesTableStore("prod");
  const { columns: injColumns, store: waterStore } = useParamPipesTableStore("inj");
  const { columns: mineColumns, store: mineStore } = useParamMinesTableStore();
  const { columns: stationColumns, store: stationStore } = useParamStationsTableStore();
  const { columns: drainColumns, store: drainStore } = useParamDrainSourcesTableStore("drain");
  const { columns: sourceColumns, store: sourceStore } = useParamDrainSourcesTableStore("source");
  const { columns: externalSourceColumns, store: externalSourceStore } = useParamExternalSourcesTableStore();

  if (mode === "mines") {
    return { columns: mineColumns, store: mineStore };
  }
  if (mode === "stations") {
    return { columns: stationColumns, store: stationStore };
  }
  if (mode === "drain") {
    return { columns: drainColumns, store: drainStore };
  }
  if (mode === "source") {
    return { columns: sourceColumns, store: sourceStore };
  }
  if (mode === "externalSource") {
    return { columns: externalSourceColumns, store: externalSourceStore };
  }
  if (mode === "prod") {
    return { columns: prodColumns, store: oilStore };
  }

  return { columns: injColumns, store: waterStore };
};

export { useParamsTableByMode };
