import type { FC } from "react";
import { DatePicker, Select } from "antd";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react";

import { ValidationInput } from "elements/inputs/validationInput/validationInput";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { useProject } from "models/project/project";
import { BINDING_SELECTOR, ForecastMode, isScalarApproximation } from "services/back/techForecast/request";

import { ApplyForGroupButton } from "../applyForAllButton";
import { ValidationInputPercent } from "../legacyInputs/validationInputPercent";
import { Param, Params } from "../param";

import { MethodParams } from "./methodParams";
import { SaveTypicalButton } from "./saveTypicalButton";

import cn from "./method.module.less";

type MethodProps = {
  mode: ForecastMode;
};

const Method: FC<MethodProps> = observer(({ mode }) => {
  const project = useProject()!;
  const { currentForecast, currentKey, typeForecastSettings } = useTechForecastModel();
  const { settings } = currentForecast;
  const modeSettings = settings[mode];
  const miningTitle = mode === "waterCut" ? "Стартовое значение" : "Стартовый дебит";
  const typicalSettings = typeForecastSettings;

  return (
    <Params>
      {currentForecast.group === "base" && (
        <Param title="Период аппроксимации">
          <div className={cn.selectors}>
            <DatePicker.RangePicker
              disabledDate={currentForecast.disallowedMothPredicate(mode)}
              picker="month"
              size="small"
              style={{ width: 180 }}
              value={currentForecast.factDateRange(mode)}
              onChange={(dates) => {
                if (dates) {
                  const [from, to] = dates;
                  currentForecast.factDateRangeHolder(mode)([from, to]);
                }
              }}
            />
          </div>
        </Param>
      )}
      <Param title="Дата начала прогноза">
        <DatePicker
          allowClear={false}
          size="small"
          picker="month"
          style={{ width: 180 }}
          disabledDate={(date: Dayjs) => project.actualStateDate >= date}
          onChange={settings.beginDateHolder}
          value={settings.beginDate ?? project.actualStateDate}
        />
      </Param>
      {currentForecast.group === "base" && (
        <Param title={<b>{miningTitle}</b>}>
          <div className={cn.selectors}>
            <Select
              style={{ width: 180 }}
              options={BINDING_SELECTOR}
              disabled={currentForecast.fact.isStoppedWell}
              value={modeSettings.bindingMode}
              onChange={modeSettings.bindingModeHolder}
              size="small"
            />
          </div>
        </Param>
      )}
      {modeSettings.bindingMode === "manual" && (
        <Param title={currentForecast.group === "base" ? <span /> : <b>{miningTitle}</b>}>
          <div className={cn.selectors}>
            <ValidationInput
              key={currentKey}
              value={modeSettings.bindingValue}
              onSave={modeSettings.bindingValueHolder}
              min={1e-10}
              max={Infinity}
              size="small"
            />
          </div>
        </Param>
      )}
      <Param title={<b>Способ расчета</b>}>
        <div className={cn.selectors}>
          <div className={cn.btns}>
            {(isScalarApproximation(modeSettings.method) || modeSettings.method === "wellsAnalog") && (
              <SaveTypicalButton
                disabledTooltip={modeSettings.isValid ? undefined : "Для сохранения типовой кривой нужно закончить ввод параметров прогноза"}
                loading={!typicalSettings}
                typicalSettings={typicalSettings}
                mode={mode}
                modeSettings={modeSettings}
                className={cn.saveTypical}
              />
            )}
            <ApplyForGroupButton className={cn.applyGroup} group={mode} />
          </div>
          <Select
            data-testid="method"
            style={{ width: 148 }}
            options={settings.methodSelector(mode)}
            value={modeSettings.method}
            onChange={modeSettings.methodSelectionHolder}
            size="small"
          />
        </div>
      </Param>
      <MethodParams mode={mode} method={modeSettings.method} />
      {(mode === "oil" || (mode === "waterCut" && settings.mode === "oilRate")) && (
        <Param title="Темп падения при корректировке, %/мес">
          <ValidationInputPercent
            key={currentKey}
            disabled={settings.isCorrectionEnabled === false}
            value={settings.correctionValue}
            onSave={settings.correctionValueHolder}
            min={1e-10}
            max={Infinity}
            size="small"
          />
        </Param>
      )}
    </Params>
  );
});

export { Method };
