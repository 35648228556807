import { createContext, ReactNode, useContext, useLayoutEffect, useMemo } from "react";
import { CheckSquareOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { makeAutoObservable, reaction } from "mobx";
import { observer } from "mobx-react-lite";

import { AlertPopover, alertPopoverTypeIcons } from "elements/alertPopover/alertPopover";
import { global } from "models/global";

type ValidationMessageType = "error" | "warning" | "debug";

type ValidatorResultInfo =
  | {
      hasMessages: boolean;
      level: number | undefined;
      levelType: ValidationMessageType;
    }
  | undefined;

const validationMessageTypeLevel: Record<ValidationMessageType, number> = {
  error: 10,
  warning: 5,
  debug: 0,
};

const validationMessageTypeLevelOrdered = Object.entries(validationMessageTypeLevel)
  .map(([type, level]) => ({ type: type as ValidationMessageType, level }))
  .sort((e) => e.level);

const getValidationMessageTypeByLevel: (value: number | undefined) => ValidationMessageType = (value) => {
  if (!value) {
    return "debug";
  }

  let currentType: ValidationMessageType = validationMessageTypeLevelOrdered[0].type;
  for (const { type, level } of validationMessageTypeLevelOrdered) {
    if (value <= level) {
      currentType = type;
    } else {
      return currentType;
    }
  }
  return currentType;
};

class ResultValidationMessage {
  public level: number;
  constructor(public type: ValidationMessageType, public text: string, public keys: string[] = []) {
    this.level = validationMessageTypeLevel[type];
  }
}

class ResultValidationChecker {
  constructor(public check: () => ResultValidationMessage[]) {}
}

class ResultValidator {
  private messages: ResultValidationMessage[] | undefined = undefined;
  constructor(private readonly toCheck: ResultValidationChecker[]) {
    makeAutoObservable(this);
  }

  public validate() {
    const result: ResultValidationMessage[] = [];
    for (const v of this.toCheck) {
      const vCheckResult = v.check();
      for (const r of vCheckResult) {
        result.push(r);
      }
    }
    this.messages = result;
  }

  public get displayedMessages() {
    if (global.IS_DEBUG_ZONE) {
      return this.messages;
    } else {
      return this.messages?.filter((m) => m.type !== "debug");
    }
  }

  private getMessagesMaxLevel(messages: ResultValidationMessage[]) {
    return messages.map((m) => m.level).reduce((a, b) => Math.max(a, b), -Infinity);
  }

  private generateValidationResultInfo(messages: ResultValidationMessage[]) {
    const level = messages.length > 0 ? this.getMessagesMaxLevel(messages) : undefined;
    return {
      level,
      levelType: getValidationMessageTypeByLevel(level),
      hasMessages: messages.length > 0,
    };
  }

  public get result(): ValidatorResultInfo {
    if (this.displayedMessages === undefined) {
      return undefined;
    }
    return this.generateValidationResultInfo(this.displayedMessages);
  }

  public resultByKeys(keys: string[]): ValidatorResultInfo {
    if (this.displayedMessages === undefined) {
      return undefined;
    }
    const filteredMessages = this.displayedMessages.filter((m) => m.keys.filter((value) => keys.includes(value)).length > 0);
    return this.generateValidationResultInfo(filteredMessages);
  }
}

class ResultValidatorManager {
  public validator: ResultValidator | null = null;
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.validator,
      () => {
        if (this.validator) {
          this.validator.validate();
        }
      }
    );
  }

  public useValidator(validator: ResultValidator) {
    this.validator = validator;
  }

  public resetValidator() {
    this.validator = null;
  }

  public get validationResult(): ValidatorResultInfo {
    if (!this.validator) {
      return undefined;
    }
    return this.validator.result;
  }

  public validationResultByKeys(keys: string[]): ValidatorResultInfo {
    if (!this.validator) {
      return undefined;
    }
    return this.validator.resultByKeys(keys);
  }
}

const ValidatorManagerContext = createContext<ResultValidatorManager | null>(null);

const useResultValidatorManager = (validator?: ResultValidator) => {
  const validatorManager = useContext(ValidatorManagerContext);
  console.assert(validatorManager != null, "Запрошен validatorManager вне контекста ValidatorManagerContext");
  useLayoutEffect(() => {
    if (validator) {
      validatorManager?.useValidator(validator);
      return () => {
        validatorManager?.resetValidator();
      };
    }
  });
  return validatorManager;
};

const ValidatorManagerContextProvider = observer(({ children }: { children: ReactNode }) => {
  const validatorManager = useMemo(() => {
    return new ResultValidatorManager();
  }, []);

  return <ValidatorManagerContext.Provider value={validatorManager}>{children}</ValidatorManagerContext.Provider>;
});

const MessageDisplay = observer(() => {
  const validatorManager = useResultValidatorManager();

  if (!validatorManager || !validatorManager.validator || !validatorManager.validator.displayedMessages || !validatorManager.validationResult) {
    return null;
  }

  return (
    <AlertPopover
      messages={validatorManager.validator!.displayedMessages}
      btnIcon={alertPopoverTypeIcons[getValidationMessageTypeByLevel(validatorManager.validationResult.level)]}
    />
  );
});

const ResultInformer = observer(() => {
  const validatorManager = useResultValidatorManager();
  if (!validatorManager) {
    return null;
  }

  const hasWarning = validatorManager.validationResult?.hasMessages;
  if (hasWarning === undefined) {
    return null;
  }

  return hasWarning ? <MessageDisplay /> : <Button disabled icon={<CheckSquareOutlined style={{ color: "green" }} />}></Button>;
});

export {
  MessageDisplay,
  ResultInformer,
  ResultValidationChecker,
  ResultValidationMessage,
  ResultValidator,
  useResultValidatorManager,
  type ValidationMessageType,
  validationMessageTypeLevel,
  ValidatorManagerContextProvider,
};
