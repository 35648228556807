import { makeAutoObservable, reaction } from "mobx";
import { isPersisting, makePersistable } from "mobx-persist-store";

import { Tree } from "elements/tree/model/tree";

import { Forecast } from "../fact/forecast/forecast";
import { Project } from "../project";

const processTreeSelect = (tree: Tree<any>, selectedIds: Set<number> | null) => {
  if (selectedIds !== null) {
    tree.selectManager.selected.replace(new Set(Array.from(selectedIds.values(), (v) => `${v}`)));
  }
};

class ScenarioComparison {
  public selectedScenarios?: number[];
  private treeState: Tree<Forecast> = new Tree(
    "Сценарии",
    [...this.project.fact.forecasts.values!],
    [],
    [],
    true,
    undefined,
    false
  );

  constructor(private project: Project) {
    this.selectedScenarios = undefined;

    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: `compare_scenario_settings_for_project_${project.id}`,
        properties: ["selectedScenarios"],
        storage: window.localStorage,
      },
      {}
    );

    reaction(
      () => [...this.project.fact.forecasts.values!].map((f) => f.id).join("-"),
      () => {
        this.treeState = new Tree("Сценарии", [...this.project.fact.forecasts.values!], [], [], true, undefined, false);
      }
    );

    reaction(
      () => this.treeState.selectedItems,
      () => {
        this.selectedScenarios = this.treeState.selectedItems.map((e) => e.id);
      }
    );
    reaction(
      () => this.isPersisting,
      () => {
        if (this.selectedScenarios) {
          processTreeSelect(this.treeState, new Set(this.selectedScenarios));
        }
      }
    );
  }

  get tree() {
    return this.treeState;
  }

  public get isPersisting() {
    return isPersisting(this);
  }
}

export { ScenarioComparison };
