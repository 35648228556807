import { ReactNode } from "react";
import { LineDataModel } from "@okopok/axes_context";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction, runInAction, when } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { ChannelsManager } from "elements/charts/channelsManager";
import { BarDataModelProps, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { Tree } from "elements/tree/model/tree";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Padding } from "features/plot/useLinesBarsComposition";
import { LineData } from "features/techForecast/wellTechForecast/results/chart/elements/line";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { getBaseWellsStatus, WellsFondsByYear } from "services/back/wellStatus";
import { createDomainAccessor } from "utils/boundDomain";

import { PARAMS, SUMMARY_FIELDS, YearDatum } from "./constants";
import { aggregate, yearDatumsToAxes } from "./utils";

import cn from "../../techSummaryChart/techSummaryChart.module.less";

class SummaryModel extends ChannelsManager {
  get knownGroups(): Record<string, { title: string; items: Set<string> }> {
    return { group: { title: "Показатели", items: new Set(SUMMARY_FIELDS.slice(2)) } };
  }
  public tooltipManager: TooltipDataManager = new TooltipDataManager();
  public threshold: Dayjs;
  public years: Dayjs[];
  public axisTitles: Map<string, ReactNode> | undefined;
  public paddings: Padding[] | undefined;
  public rightAxes: Set<string> | undefined;
  public lines: { line: LineDataModel; channel: string }[] | undefined;
  public bars: BarsDataModel | undefined;
  public rawBars: { bar: BarDataModelProps; channel: string }[] | undefined;
  public data: YearDatum[] = [];
  public baseFondFromBackend: WellsFondsByYear | undefined;

  constructor(public tree: Tree<Well>, public fc: Forecast) {
    super();
    makePersistable<SummaryModel, "hidden" | "colors">(this, {
      name: `tech-summary-chart-channels`,
      properties: [
        {
          key: "hidden",
          serialize: (value) => JSON.stringify([...value.values()]),
          deserialize: (value) => observable.set<string>(JSON.parse(value)),
        },
        {
          key: "colors",
          serialize: (value) => JSON.stringify([...value.entries()]),
          deserialize: (value) => observable.map<string, string>(JSON.parse(value)),
        },
      ],
      storage: window.localStorage,
    });
    makeObservable(this, {
      data: observable,
      baseFondFromBackend: observable.ref,
      lines: observable,
      bars: observable,
      rawBars: observable,
      aggregateData: action,
      init: action,
      updateLineColor: action,
      activeChannels: computed,
    });
    this.threshold = dayjs(`${fc.range.from}`);
    this.years = [...this.fc.wholeRange].map((year) => dayjs(`${year}-01-01`));

    getBaseWellsStatus(this.fc.id).then((fonds) => runInAction(() => (this.baseFondFromBackend = fonds)));

    this.init();
    reaction(
      () => [...this.tree.selectedNesting],
      () => {
        this.init();
      }
    );

    reaction(
      () => [...this.hidden.values(), ...(this.rawBars ?? []).map((bar) => bar.bar.color)],
      () => {
        this.bars =
          (this.rawBars?.filter((item) => !this.hidden.has(item.channel)).map((item) => item.bar) ?? []).length > 0
            ? new BarsDataModel(this.rawBars?.filter((item) => !this.hidden.has(item.channel)).map((item) => item.bar) ?? [], "bars", true)
            : undefined;
      }
    );
  }

  public async init() {
    await this.aggregateData();

    const { lines, bars } = yearDatumsToAxes(this.data);
    const preparedLines: { line: LineDataModel; channel: string }[] = [];
    lines.forEach((axis, id) => {
      axis.dataSet.forEach((line, index) => {
        const accessor = createDomainAccessor<LineData["x"]>(this.years, line.y.length);
        if (line.y.filter((value, index) => (this.threshold ? accessor(index) <= +this.threshold : true)).length > 0) {
          preparedLines.push({
            line: new LineDataModel({
              y: line.y.filter((value, index) => (this.threshold ? accessor(index) <= +this.threshold : true)),
              x: this.years.filter((value) => (this.threshold ? value <= this.threshold : true)),
              axisKey: axis.unit,
              key: `axis${id}-${index}-0`,
              color: this.channelColor(line.channel),
              title: line.title,
            }),
            channel: line.channel,
          });
        }
        if (line.y.filter((value, index) => (this.threshold ? accessor(index) >= +this.threshold : false)).length > 0) {
          preparedLines.push({
            line: new LineDataModel({
              y: line.y.filter((value, index) => (this.threshold ? accessor(index) >= +this.threshold : false)),
              x: this.years.filter((value) => (this.threshold ? value >= this.threshold : false)),
              axisKey: axis.unit,
              key: `axis${id}-${index}-1`,
              color: this.channelColor(line.channel),
              className: cn.dashed,
              title: line.title,
            }),
            channel: line.channel,
          });
        }
      });
    });

    this.rawBars = bars
      ? bars.dataSet.map((bar, index) => ({
          bar: {
            y: bar.y.map((value) => ({ value: value })),
            x: this.years,
            color: this.channelColor(bar.channel),
            key: `bar${index}`,
            title: bar.title,
          },
          channel: bar.channel,
        }))
      : [];

    const barsModel = new BarsDataModel(this.rawBars?.filter((item) => !this.hidden.has(item.channel)).map((item) => item.bar) ?? [], "bars", true);

    this.axisTitles = new Map<string, ReactNode>(lines.map((line, index) => [line.unit, line.unit]));
    this.axisTitles.set("bars", bars?.unit);
    this.rightAxes = new Set<string>(["bars", ...lines.filter((line) => line.side === "right").map((line) => line.unit)]);

    this.paddings = [...lines.map((line, index) => ({ axisKey: line.unit, bottom: 0 })), { axisKey: "bars", top: 0.5 }];

    this.lines = preparedLines;
    this.bars = barsModel;
  }

  public async aggregateData() {
    await when(() => this.baseFondFromBackend !== undefined);
    const nesting = this.tree.selectedNesting;
    const selected: { wellId: number; producingObjectsIds?: number[] }[] = nesting.map((item) => ({
      wellId: item.item.id,
      producingObjectsIds: item.paths.length === 0 ? undefined : item.paths.map((p) => parseInt(p.key)),
    }));
    this.data = await aggregate(this.fc.wholeRange, selected, this.fc.production, this.fc.fact.lastDescribedYear, this.baseFondFromBackend!);
  }

  updateLineColor = (key: string) => {
    runInAction(() => {
      const line = this.lines?.find((line) => line.channel === key)?.line;
      if (line) {
        line.color = this.colors.get(key);
      }
      const bar = this.rawBars?.find((bar) => bar.channel === key)?.bar;
      if (bar) {
        bar.color = this.colors.get(key);
      }
    });
  };

  get activeChannels() {
    return Object.entries(this.channelsMetaInfo).filter((channel) => !this.hidden.has(channel[0]));
  }

  get channelsMetaInfo() {
    return PARAMS;
  }
}
export { SummaryModel };
