import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction, runInAction, transaction } from "mobx";

import { Lazy } from "models/lazy";
import { Debet, EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import { Project } from "models/project/project";
import { ForecastMode, TechResultsForSave } from "services/back/techForecast/request";
import { TechForecastSettings } from "services/back/techForecast/settings";
import { getTypeSettings, saveTypeSettings } from "services/back/techForecast/typeSettings";

import { WellTechForecast } from "./well/wellTechForecast";
import { SettingsSave } from "./well/wellTechForecastSettings";
import { GroupedEvents } from "./groupedEvents";
import { LegendSettings } from "./legendSettings";
import { TypeForecastSettings } from "./typeForecastSettings";

const TITLE_MAP = {
  base: "Базовый",
  new: "Новый",
  gtm: "ГТМ",
} as const;

const WELL_GROUPS_LIST = ["base", "new", "gtm"] as const;

const MODE_GENITIVE_CASE = { oil: "нефти", liquid: "жидкости", waterCut: "обводнённости" } as const;

type DisplayMode = "chart" | "table" | "debug" | "lnvnf" | "recovery";

class TechForecastModel {
  readonly collapsed = observable.set();
  wells: GroupedEvents;
  selected: number = 0;
  readonly settings = new Map<EventNode, WellTechForecast>();
  isLoading = false;

  #typeForecastSettings = new Lazy(() => new TypeForecastSettings(this.projectId, getTypeSettings, saveTypeSettings));
  public legendSettings = new LegendSettings();

  resultsDisplayMode: DisplayMode = "chart";

  constructor(readonly selection: Debet, readonly project: Project) {
    makeObservable<TechForecastModel, "settingsSave">(this, {
      wells: observable,
      selected: observable,
      settings: observable,
      isLoading: observable,
      resultsDisplayMode: observable,

      settingsSave: computed,
      currentSelection: computed,
      currentGroup: computed,
      currentKey: computed,
      selectedIndex: computed,
      currentForecast: computed,
      wellGroupsCount: computed,
      amount: computed,
      forecastReadyCount: computed,
      savedCount: computed,
      projectId: computed,
      typeForecastSettings: computed,
      resultsDisplayModeHolder: action,

      updateSingleSetting: action,
      forecast: action,
      save: action,
    });

    reaction(
      () => selection.selectedEvents,
      () => {
        this.wells = new GroupedEvents(this.selection.selectedEvents);
      }
    );
    this.wells = new GroupedEvents(this.selection.selectedEvents);
  }

  get actualStateDate(): Dayjs {
    return this.selection.forecast.fact.project.actualStateDate;
  }

  private get settingsSave() {
    return this.selection.forecast.techForecastSettings;
  }

  recoverIndexToUnCollapsed(index: number, groupIndex: number): number {
    let result = index;
    for (let i = 0; i < groupIndex; ++i) {
      if (this.collapsed.has(i)) {
        result += this.wells.at(i).length;
      }
    }
    return result;
  }

  get currentSelection(): EventNode {
    return this.selection.selectedEvents[this.selected] ?? this.selection.selectedEvents[0];
  }

  updateTechFlags(results: TechResultsForSave[]) {
    return this.selection.forecast.techForecastFlags.update(results);
  }

  updateSingleSetting({ data, ...keys }: TechForecastSettings<SettingsSave>) {
    return this.settingsSave.update(keys, data);
  }

  getSettingsSave({
    wellId,
    gtmId,
    stratumId,
  }: Omit<TechForecastSettings<never>, "data">): SettingsSave | null | undefined {
    return this.settingsSave.get({ wellId, gtmId, stratumId });
  }

  get currentGroup(): (typeof WELL_GROUPS_LIST)[number] {
    return GroupedEvents.wellMode(this.currentSelection);
  }

  get currentKey(): string {
    // на самом деле можно поправить validationInput но такое решение проще и надёжнее хоть и хуже в остальном
    return `${this.currentSelection.well.id}_${this.currentSelection.intervention?.id}`;
  }

  get selectedIndex(): number {
    return this.wells.indexAt(this.currentSelection);
  }

  get currentForecast(): WellTechForecast {
    return this.forecast(this.currentSelection);
  }

  forecast(event: EventNode): WellTechForecast {
    const fc = this.settings.get(event);
    if (fc !== undefined) {
      return fc;
    }
    const created = new WellTechForecast(event, this);
    this.settings.set(event, created);
    return created;
  }

  groupTitle(index: number): string {
    return TITLE_MAP[WELL_GROUPS_LIST[index]];
  }

  get wellGroupsCount(): number[] {
    return WELL_GROUPS_LIST.map((_, index) => (this.collapsed.has(index) ? 0 : this.wells.at(index).length)).filter(
      (_, index) => this.wells.at(index).length !== 0
    );
  }

  collapseGroupHolder(index: number) {
    return () => runInAction(() => this.collapsed[this.collapsed.has(index) ? "delete" : "add"](index));
  }

  selectionHolder = (index: number) => {
    this.selectedHolder(this.wells.atIndex(index))();
  };

  selectedHolder(node: EventNode) {
    return () =>
      runInAction(() => {
        this.selected = this.selection.selectedEvents.findIndex((v) => v === node);
      });
  }

  removeHolder(index: number) {
    return (e?: React.MouseEvent) => {
      e?.stopPropagation();
      runInAction(() =>
        transaction(() => {
          // @todo добавить пересчет позиции если она ниже удалённой
          this.wells.atIndex(index)?.selectManager?.propagateDeselected();
        })
      );
    };
  }

  resultsDisplayModeHolder = (value: DisplayMode) => {
    this.resultsDisplayMode = value;
  };

  onApplyForGroup(settingsGroup: "stopCriterion" | ForecastMode | "correction") {
    return () =>
      runInAction(() =>
        transaction(() => {
          const srcSettings = this.currentForecast.settings;
          if (settingsGroup === "correction") {
            const { isCorrectionEnabled, correctionValue, metricForCompute } = srcSettings;
            for (const selection of this.wells[this.currentGroup])
              if (selection !== this.currentSelection) {
                const { settings } = this.forecast(selection);
                settings.isCorrectionEnabled = isCorrectionEnabled;
                settings.correctionValue = correctionValue;
                settings.metricForCompute = metricForCompute;
              }
            return;
          }
          const forApply = srcSettings[settingsGroup].dump;
          const ptrDump = srcSettings.prodTimeRatio.dump;
          for (const selection of this.wells[this.currentGroup])
            if (selection !== this.currentSelection) {
              const { settings } = this.forecast(selection);
              settings[settingsGroup].applyDump(forApply as any);
              if (settingsGroup === "stopCriterion") {
                settings.prodTimeRatio.prodTimeRatio = ptrDump;
              }
            }
        })
      );
  }

  get isUpdated(): boolean {
    return true;
  }
  get isCompleted(): boolean {
    return true;
  }
  get isValid(): boolean {
    return true;
  }

  get onSave() {
    return "Сохранить все настройки и результаты прогноза добычи";
  }
  get onNotUpdated() {
    return "onNotUpdated";
  }
  get onNotCompleted() {
    return "onNotCompleted";
  }
  get onNotValid() {
    return "onNotValid";
  }

  get amount(): number {
    return this.selection.selectedEvents.length;
  }

  get projectId(): number {
    return this.selection.forecast.fact.projectId;
  }

  get forecastReadyCount(): number {
    return [...this.settings.values()].filter((v) => v.currentResult?.isLoading === false).length;
  }

  get savedCount(): number {
    return [...this.settings.values()].filter((v) => v.isSaved).length;
  }

  public get typeForecastSettings(): TypeForecastSettings {
    return this.#typeForecastSettings.value;
  }

  save = action(async () => {
    this.isLoading = true;
    const forecasts = this.selection.selectedEvents.map((e) => this.forecast(e));
    try {
      await Promise.all(forecasts.map((fc) => fc.submit()));
      this.settingsSave.updateProduction(
        forecasts.map((fc) => fc.key),
        "save"
      );
    } catch {}
    this.isLoading = false;
  });
}

export { MODE_GENITIVE_CASE, TechForecastModel, TITLE_MAP, WELL_GROUPS_LIST };
