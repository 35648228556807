import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { computed, makeObservable } from "mobx";

import { BoundaryConditionType } from "services/back/infrastructure/infrastructure";

type DRow = {
  date: string | null;
  oilRate: number | null;
  waterRate: number | null;
  isComplete: boolean;
};

class BoundaryCondition extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data,
    isComplete: this.isComplete,
  });
  constructor(private parent: BoundaryConditions, private data: BoundaryConditionType) {
    super(parent);

    makeObservable(this, {
      isComplete: computed,
    });
  }

  public get isComplete(): boolean {
    const { waterRate, oilRate, date } = this.data;
    return ![waterRate, oilRate, date].includes(null);
  }
}

class BoundaryConditions extends TableNode<DRow, BoundaryCondition> {
  constructor(private data: BoundaryConditionType[]) {
    super();

    this.initChildren();
  }

  private initChildren() {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.data.map((el) => new BoundaryCondition(this, el))
    );
  }

  public get isComplete(): boolean {
    return [...(this.children ?? [])]?.every(({ isComplete }) => isComplete) ?? false;
  }

  public get isValid(): boolean {
    if (!this.childrenStore) {
      return false;
    }
    if (this.childrenStore.size === 0) {
      return true;
    }
    return this.isComplete;
  }

  public get newRows() {
    return this.data;
  }
}

export { BoundaryConditions, type DRow };
