import { when } from "mobx";

import { TreeFilter } from "elements/tree/model/filters/filters";
import { Tree } from "elements/tree/model/tree";
import { kindInference } from "features/preloader/preloader";
import { producingObjectNestingField, TREE_NESTING_FIELDS } from "models/forecastContext/wellsTreeNest";
import { global } from "models/global";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";

import { type ProjectData } from "./projectContext";

const generateEmptyWellTree = () => new Tree<Well>("Все", [], [], []);

const createWellsTreeFilters = (forecast: Forecast | undefined | null): TreeFilter<Well, any>[] => {
  console.assert(global.wellTypes.isLoading === false, "Попытка отображения до завершения загрузки");

  if (!forecast) {
    return [];
  }

  return [
    {
      title: "Фонд",
      options: ["Базовый", "Новый"],
      predicateFactory: (selected) => (well: Well) =>
        selected.map((item) => (item === "Базовый" ? "Base" : "New")).includes(well.fond),
    },
    {
      title: "Лицензионные участки",
      options: forecast.licenseRegions.titles,
      predicateFactory: (selected) => (well: Well) => selected.includes(well.licenseRegion?.title ?? ""),
    },
    {
      title: "Назначение скважины",
      options: ["Добывающая", "Нагнетательная", "Нет истории работы", "Прочего назначения"],
      predicateFactory: (selected) => (well: Well) => {
        const status = forecast.production.wellStatus(well.id);
        if (selected.includes("Добывающая")) {
          if (status?.isMining) {
            return true;
          }
        }
        if (selected.includes("Нагнетательная")) {
          if (status?.isInjecting) {
            return true;
          }
        }
        if (selected.includes("Нет истории работы")) {
          if (status === undefined) {
            return true;
          }
        }
        if (selected.includes("Прочего назначения")) {
          return true;
        }
        return false;
      },
    },
  ];
};

const onUpdateForecast = (target: ProjectData, forecast: Forecast | null) => {
  target.wellsTree = generateEmptyWellTree();
  when(
    () => {
      const isLoading = (() => {
        if (kindInference(target.project?.fact?.wells)) return true;
        if (kindInference(target.project?.fact?.wellPads)) return true;
        if (kindInference(target.project?.fact?.stratums)) return true;
        if (kindInference(target.project?.fact?.producingObjects)) return true;
        if (kindInference(target.project?.fact?.licenseRegions)) return true;
        if (kindInference(target.forecast)) return true;
        if (target.forecast !== null && kindInference(target.forecast?.wells)) return true;
        if (target.forecast !== null && kindInference(target.forecast?.interventions)) return true;
        return false;
      })();
      return !isLoading;
    },
    () => {
      let wells: Well[];
      if (target.forecast) {
        wells = target.forecast.wells.allWells;
      } else if (target.project?.fact) {
        wells = target.project.fact.wells.allWells;
      } else {
        wells = [];
      }
      const wellTreeFilters = createWellsTreeFilters(forecast);

      const wellTree = new Tree(
        "Все",
        wells,
        [...TREE_NESTING_FIELDS, producingObjectNestingField(forecast)],
        wellTreeFilters
      );
      target.wellsTree = wellTree;
    }
  );
};

export { generateEmptyWellTree, onUpdateForecast };
