import { FC, ReactNode } from "react";
import { Virtuoso } from "react-virtuoso";
import { Checkbox } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ExpandButton } from "elements/expandButton/expandButton";

import { useTreeContext } from "./model/context";
import { TreeListNode } from "./model/tree";
import { isNestNode } from "./model/types";

import cn from "./treeList.module.less";

type Renderer = (data: any) => ReactNode;

const Item: FC<{
  data: TreeListNode<any>;
  render: Renderer;
}> = observer(({ data, render }) => {
  const isSelected = data.selectionManager.isSelectedNode(data);

  return (
    <div className={cn.item} style={{ paddingLeft: `${data.level * 18 + (isNestNode(data) ? 0 : 24)}px` }}>
      <ExpandButton expand={isNestNode(data) ? data.expand : undefined} />
      {data.selectionManager.isSingleSelect ? (
        <div className={classNames(cn.select, isSelected && cn.selected)} onClick={() => data.selectionManager.select(data)}>
          {isNestNode(data) ? <>{data.data}</> : render(data.data)}
        </div>
      ) : (
        <Checkbox
          indeterminate={isSelected === "partially"}
          checked={!!isSelected}
          onChange={() => data.selectionManager.select(data)}
          className={cn.checkbox}
        >
          {isNestNode(data) ? <>{data.element}</> : render(data.data)}
        </Checkbox>
      )}
    </div>
  );
});

const PLAIN = (_: number, data: TreeListNode<any>, { render }: { render: Renderer }) => {
  return <Item data={data} render={render} />;
};

const TreeList: FC<{ render: Renderer }> = observer(({ render }) => {
  const tree = useTreeContext();

  return <Virtuoso itemContent={PLAIN} data={tree.list} context={{ render }} />;
});

export { TreeList };
