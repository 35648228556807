import { ReactNode, useCallback, useMemo } from "react";
import { Button, Tooltip } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";

import { Icon } from "elements/icon/icon";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column } from "features/tableDebug/simpleTable";
import { GenericTableRow } from "services/back/genericTable/genegicTableService";

import { ExternalSources } from "../../models/externalSources";

import { useImportBoundaryConditionsModal } from "./modal/importBoundaryConditionsModal";
import { ReactComponent as UploadFileIcon } from "./uploadFile.svg";

import cn from "../../infrastructureParamsTable.module.less";

const useCustomRenders = (): Record<string, (value: any, row: GenericTableRow) => ReactNode> => {
  const importBoundaryConditions = useImportBoundaryConditionsModal();

  const { nodes } = useInfrastructure();

  const handleClick = useCallback(
    (row: any) => {
      importBoundaryConditions().then((data) => {
        nodes.setExternalSourceBoundaryConditions(row.value.uuid, data?.newRows ?? []);
        row.update("isHaveBoundaryConditions", true);
      });
    },
    [importBoundaryConditions, nodes]
  );

  return useMemo(
    () => ({
      isHaveBoundaryConditions: (value: boolean | null | undefined, row: any) => {
        return (
          <div className={cn.boundaryConditionsCell}>
            <div>{value ? "Загружены" : "Не загружены"}</div>
            <Tooltip title="Загрузить граничные условия для внешнего источника">
              <Button
                type="text"
                icon={<Icon onClick={() => handleClick(row)} width="16px" height="16px" viewBox="0 0 16 16" content={<UploadFileIcon />} />}
              />
            </Tooltip>
          </div>
        );
      },
    }),
    [handleClick]
  );
};

const useColumns = () => {
  const { range } = useInfrastructure()!;
  return useMemo(
    (): Column[] => [
      {
        title: "Название",
        width: { min: 200, max: 300, competitiveness: 1 },
        dataKey: "title",
        type: "string",
        editable: true,
        hideFilter: true,
        onCell: () => ({ className: classNames(cn.tableFirstCell, cn.tableCell) }),
      },
      {
        title: "Дата ввода",
        width: { min: 150, max: 170, competitiveness: 1 },
        dataKey: "startedAt",
        type: "date",
        renderFormat: "month",
        start: dayjs().year(range.from),
        end: dayjs().year(range.to),
        editable: true,
        hideFilter: true,
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell) }),
      },
      {
        title: "Дата выбытия",
        width: { min: 150, max: 170, competitiveness: 1 },
        dataKey: "finishedAt",
        type: "date",
        renderFormat: "month",
        start: (tableItem) => dayjs(tableItem.value?.startedAt),
        end: dayjs().year(range.to),
        editable: true,
        hideFilter: true,
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell) }),
      },
      {
        title: "Координаты, м",
        width: { min: 240, max: 260, competitiveness: 1 },
        dataKey: "coords",
        onCell: () => ({ className: cn.tableCell }),
        hideFilter: true,
        type: "string",
      },
      {
        title: "Альтитуда, м",
        width: { min: 140, max: 160, competitiveness: 1 },
        dataKey: "altitude",
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell) }),
        hideFilter: true,
        editable: true,
        type: "number",
      },
      {
        title: "Граничные условия",
        width: { min: 190, max: 210, competitiveness: 1 },
        key: "isHaveBoundaryConditions",
        dataKey: "isHaveBoundaryConditions",
        hideFilter: true,
        onCell: () => ({ className: cn.tableCell }),
        type: "string",
      },
    ],
    [range]
  );
};

const useParamExternalSourcesTableStore = () => {
  const infrastructure = useInfrastructure();
  const columns = useColumns();
  const store = useMemo(() => new ExternalSources(infrastructure), [infrastructure]);
  return { columns, store };
};

export { useCustomRenders, useParamExternalSourcesTableStore };
