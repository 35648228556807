import { action, computed, makeObservable, observable, reaction } from "mobx";

import { AlertMessageType } from "elements/alertPopover/alertPopover";

import { Infrastructure } from "./infrastructure";
import { PipeType } from "./pipes";

type GraphType = { [k: string]: string[] };

class PipesGraph {
  errors = observable.array<string>();
  unconnectedNodes: Map<string, string[]> = new Map();
  unconnectedPipes: Map<string, string[]> = new Map();

  constructor(private parent: Infrastructure) {
    makeObservable(this, {
      errors: observable,
      unconnectedNodes: observable,
      unconnectedPipes: observable,
      nodes: computed,
      drainNodeIds: computed,
      sourceNodeIds: computed,
      adjacencyList: computed,
      errorsString: computed,
      validatePipesConnectivity: action,
      validateNodesConnectivity: action,
      validateConnectivity: action,
    });

    this.validateConnectivity();
  }

  get nodes() {
    const nodes = (this.parent.nodes.data ?? []).filter((node) => node.type !== "node");
    return Infrastructure.filterDisabled(nodes, true, this.parent.currentDate);
  }

  get drainNodeIds() {
    return new Set(this.parent.nodes.drains.map((node) => node.uuid));
  }
  get sourceNodeIds() {
    return new Set(this.parent.nodes.sources.map((node) => node.uuid));
  }

  get adjacencyList() {
    return PipesGraph.buildAdjacencyList(this.parent.pipes.data ?? []);
  }

  public get errorsString() {
    const moreErrors = this.errors.length > 2 ? this.errors.length - 2 : 0;
    const moreText = !!moreErrors ? ` еще (${moreErrors})` : "";
    return `${this.errors.slice(0, !!moreErrors ? 2 : this.errors.length).join(", ")}` + moreText;
  }

  public validateConnectivity = () => {
    this.errors.clear();
    this.unconnectedNodes.clear();
    this.unconnectedPipes.clear();
    this.validatePipesConnectivity();
    this.validateNodesConnectivity();
  };

  validatePipesConnectivity = () => {
    (this.parent.pipes.data ?? []).forEach((pipe) => {
      const startNode = pipe.from;
      const targetNodes = pipe.segmentType === "prod" ? this.drainNodeIds : this.sourceNodeIds;
      if (!PipesGraph.pathExists(startNode, targetNodes, this.adjacencyList)) {
        if (this.unconnectedPipes.has(pipe.segmentType)) {
          this.unconnectedPipes.set(pipe.segmentType, [...this.unconnectedPipes.get(pipe.segmentType)!, pipe.title]);
        } else {
          this.unconnectedPipes.set(pipe.segmentType, [pipe.title]);
        }
      }
    });
  };

  validateNodesConnectivity = () => {
    const visited = new Set<string>();
    const queue = [...this.drainNodeIds, ...this.sourceNodeIds];
    const graph = this.adjacencyList;

    while (queue.length > 0) {
      const current = queue.shift();
      if (!current) continue;
      if (visited.has(current)) continue;
      visited.add(current);

      if (graph[current]) {
        graph[current].forEach((neighbor) => {
          if (!visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      }
    }

    const nodes = this.nodes
      .filter(({ uuid }) => !visited.has(uuid))
      .filter((el) => el.type === "mine" || el.type === "pumping" || el.type === "externalSource");

    nodes.forEach((el) => {
      if (this.unconnectedNodes.has(el.type)) {
        this.unconnectedNodes.set(el.type, [...this.unconnectedNodes.get(el.type)!, el.title]);
      } else {
        this.unconnectedNodes.set(el.type, [el.title]);
      }
    });
  };

  private static buildAdjacencyList = (pipes: PipeType[]) => {
    const graph: GraphType = {};
    pipes.forEach((pipe) => {
      if (!graph[pipe.from]) graph[pipe.from] = [];
      if (!graph[pipe.to]) graph[pipe.to] = [];
      graph[pipe.from].push(pipe.to);
      graph[pipe.to].push(pipe.from);
    });
    return graph;
  };

  private static pathExists = (startNode: string, targetNodes: Set<string>, graph: GraphType) => {
    const visited = new Set();
    const queue = [startNode];

    while (queue.length > 0) {
      const current = queue.shift();
      if (!current) continue;
      if (visited.has(current)) continue;
      visited.add(current);

      if (targetNodes.has(current)) return true;

      if (graph[current]) {
        graph[current].forEach((neighbor) => {
          if (!visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      }
    }
    return false;
  };
}

class InfrastructureValidator {
  pipesGraph = new PipesGraph(this.parent);
  validationErrors = observable.array<AlertMessageType>();

  constructor(public parent: Infrastructure) {
    makeObservable(this, {
      validationErrors: observable,
      pipesGraph: observable,
      validate: action,
      resetValidationErrors: action,
      hasNoPipes: computed,
      validationTooltip: computed,
      hasUncategorizedPipes: computed,
      hasNoDrains: computed,
      hasNoSources: computed,
      isCalculating: computed,
      isProductionLoading: computed,
      hasDisconnected: computed,
      missingBoundaryConditions: computed,
      cannotCalculate: computed,
      isNoValid: computed,
      isNoStartedAtPipes: computed,
      isNoLengthPipes: computed,
    });

    this.validate();

    reaction(() => {
      const { parent } = this;
      if (!parent.forecast) return;

      const productionLoading = parent.forecast.production.isLoading;
      const pipesLength = parent.pipes.data?.length;
      const nodesLength = parent.nodes.data?.length;
      const calculation = parent.calculateStore.isCalculation;
      return [productionLoading, pipesLength, nodesLength, calculation, parent.isUpdated];
    }, this.validate);
  }

  get isProductionLoading() {
    if (!this.parent.forecast) return false;
    return this.parent.forecast.production.isLoading;
  }

  get hasDisconnected() {
    return !!this.pipesGraph.errors.length;
  }

  get hasNoPipes() {
    return (this.parent.pipes.data ?? []).length === 0;
  }

  get hasUncategorizedPipes() {
    return (this.parent.pipes.data ?? []).filter((el) => el.category === null).map((el) => el.title);
  }

  get hasNoDrains() {
    return !!this.parent.pipes.oilPipes.length && !this.parent.nodes.drains.length;
  }

  get hasNoSources() {
    return !!this.parent.pipes.waterPipes.length && !this.parent.nodes.sources.length;
  }

  get isNoValid() {
    return this.parent.pipes.data?.some((el) => el.startedAt === null || !el.length);
  }

  get isNoStartedAtPipes() {
    return (this.parent.pipes.data ?? []).filter((el) => el.startedAt === null).map((el) => el.title);
  }
  get isNoLengthPipes() {
    return (this.parent.pipes.data ?? []).filter((el) => !el.length).map((el) => el.title);
  }

  get missingBoundaryConditions() {
    if (this.parent.nodes.drains.length || this.parent.nodes.sources.length) {
      const notPressureDrainSources = [...this.parent.nodes.drains, ...this.parent.nodes.sources].filter(
        (el) => !this.parent.drainSourceBoundaryConditions.some((item) => el.uuid === item.nodeUuid)
      );
      return notPressureDrainSources.map((el) => el.title);
    }
    return [];
  }

  get isCalculating() {
    return this.parent.calculateStore.isCalculation;
  }

  public get validationTooltip() {
    if (this.isCalculating) return "Идет расчет";
    if (this.isProductionLoading) return "Подготовка добычи";
    if (this.cannotCalculate) return "Невозможно выполнить расчет, проверьте перечень предупреждений";
    return "";
  }

  public get cannotCalculate() {
    return !!this.validationErrors.length;
  }

  public validate = () => {
    this.resetValidationErrors();
    this.pipesGraph.validateConnectivity();

    if (this.hasNoPipes) {
      const title = "Не задан ни один трубопровод";
      this.validationErrors.push({ type: "error", title, text: title });
    }

    if (this.hasNoDrains || this.hasNoSources) {
      const title = "Не задан сток";
      this.validationErrors.push({ type: "error", title, text: title });
    }

    if (this.hasNoSources) {
      const title = "Не задан источник";
      this.validationErrors.push({ type: "error", title, text: title });
    }

    if (this.hasUncategorizedPipes.length > 0) {
      this.validationErrors.push({
        type: "error",
        title: "Трубоводы без обвязки (не выбран тип трубопровода)",
        text: this.hasUncategorizedPipes.join(", "),
      });
    }

    if (this.pipesGraph.unconnectedPipes.size > 0) {
      this.pipesGraph.unconnectedPipes.forEach((v, k) => {
        this.validationErrors.push({
          type: "error",
          title: `${k === "prod" ? "Нефтепроводы" : "Водоводы"} не подключены к ${
            k === "prod" ? "стоку" : "источнику"
          }`,
          text: v.join(", "),
        });
      });
    }

    if (this.pipesGraph.unconnectedNodes.size > 0) {
      this.pipesGraph.unconnectedNodes.forEach((v, k) => {
        const title = k === "mine" ? "Кусты" : k === "externalSource" ? "Внешние источники" : "Насосные станции";
        this.validationErrors.push({
          type: "error",
          title: `${title} не подключены к стоку / источнику`,
          text: v.join(", "),
        });
      });
    }

    if (this.missingBoundaryConditions.length > 0) {
      this.validationErrors.push({
        type: "error",
        title: "Давление на стоке / источнике не задано",
        text: this.missingBoundaryConditions.join(", "),
      });
    }

    if (this.isNoLengthPipes.length > 0) {
      this.validationErrors.push({
        type: "error",
        title: "Не задана протяженность трубопроводов",
        text: this.isNoLengthPipes.join(", "),
      });
    }

    if (this.isNoStartedAtPipes.length > 0) {
      this.validationErrors.push({
        type: "error",
        title: "Не задана дата ввода трубопроводов",
        text: this.isNoStartedAtPipes.join(", "),
      });
    }
  };

  public resetValidationErrors = () => {
    this.validationErrors.clear();
  };
}

export { InfrastructureValidator };
