import { FC } from "react";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";

import { Icon } from "elements/icon/icon";

import { ReactComponent as ImportMinesIcon } from "./importMines.svg";
import { ReactComponent as ImportMinesWarningIcon } from "./importMinesWarning.svg";
import { useMines } from "./mines";
import { useImportMinesModal } from "./useImportMinesModal";

import cn from "./importMines.module.less";

const ImportMines: FC = observer(() => {
  const importMinesModal = useImportMinesModal();

  const mines = useMines();

  const isNoMines = mines.childrenStore?.length === 0;

  return (
    <Tooltip title={!isNoMines ? "Есть кусты не отображенные в инфраструктуре" : undefined}>
      <Button
        disabled={isNoMines}
        icon={<Icon width="20" height="20" viewBox="0 0 20 20" content={!isNoMines ? <ImportMinesWarningIcon /> : <ImportMinesIcon />} />}
        onClick={() => importMinesModal(mines)}
        className={cn.warningMines}
      />
    </Tooltip>
  );
});

export { ImportMines };
