import { FC, PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";

import { TreeContextProvider } from "elements/tree/model/context";
import { Tree } from "elements/tree/tree";
import { useProjectContext } from "models/project/context/projectContext";
import { Well } from "models/project/fact/well/well";

import { useWellTreePreloading } from "./useWellTreePreloading";

type WellsTreeProps = {
  title: string;
  className?: string;
};

const wellRender = (well: Well) => well.title;

const WellsTree: FC<WellsTreeProps & PropsWithChildren> = observer(({ title, className, children }) => {
  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;
  return (
    <TreeContextProvider value={tree}>
      <Tree render={wellRender} title={title} className={className} preloadHooks={[useWellTreePreloading(tree)]}>
        {children}
      </Tree>
    </TreeContextProvider>
  );
});

export { WellsTree };
