import { FC, useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { Checkbox } from "antd";
import { observer } from "mobx-react";

import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { ParamsRow } from "features/useMetrics/paramsRow";
import { OptionedParam } from "models/params/optionedParams/optionedParam";
import { Range } from "utils/range";

import { ScalarTableStore } from "./scalarTableModel";

import cn from "./scalar.module.less";

const useColumns = (range: Range) =>
  useMemo(
    (): Column[] => [
      {
        title: "Параметр",
        dataKey: "param",
        type: "string",
        width: 350,
        showEditRowBtn: true,
        hideFilter: true,
      },
      {
        title: "Единица измерения",
        dataKey: "measure",
        type: "string",
        width: 100,
      },
      ...[...range].map(
        (year): Column => ({
          title: `${year}`,
          dataKey: `${year}`,
          type: "number",
          width: 140,
          editable: true,
          hideFilter: true,
        })
      ),
    ],
    [range]
  );

type Props = {
  value: OptionedParam;
  visible: boolean;
  onVisibilityChange?: (v: boolean) => void;
};

const InputUnitCosts: FC<Props> = observer(({ value, visible, onVisibilityChange }) => {
  const columns = useColumns(value.range);

  const setValue = value.inputMethod === "primary" ? value.setPrimary : value.setSecondary;

  const values = useMemo(() => {
    const v = value.inputMethod === "primary" ? value.primary : value.secondary;
    return Array.isArray(v) ? v : Array([...value.range].length).fill(v);
  }, [value.inputMethod, value.primary, value.secondary, value.range]);

  const dataItems = useMemo(
    (): ParamsRow[] => [
      {
        key: 0,
        row: 0,
        title: "Задать значение",
        measure: "тыс ₽/скв",
        level: 0,
        values,
      },
    ],
    [values]
  );

  const data = useMemo(() => new ScalarTableStore(value.range, dataItems, setValue), [value.range, dataItems, setValue]);

  return (
    <div className={cn.inputUnitCostsContainer}>
      <Checkbox className={cn.unitCostsCheckbox} checked={visible} onChange={({ target }) => onVisibilityChange?.(target.checked)}>
        Ввод удельных затрат по годам
      </Checkbox>
      {visible && (
        <div className={cn.inputUnitCostsTableWrapper}>
          <SimpleTableContext exportFileName="unitCosts" columns={columns} hideExpandColumn data={data}>
            <div style={{ height: "100%" }}>
              <Table headerClassName={cn.tableHeader} className={cn.table} />
            </div>
          </SimpleTableContext>
        </div>
      )}
    </div>
  );
});

export { InputUnitCosts };
