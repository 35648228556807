import { TreeContextProvider } from "elements/tree/model/context";
import { type Nest, Tree as TreeModel } from "elements/tree/model/tree";
import { Tree as TreeComponent } from "elements/tree/tree";
import { TreeSelectionDebug } from "elements/tree/treeSelectionDebug";

type Item = {
  title: string;
  id: number;
};

const ITEMS: Array<Item> = [
  {
    title: "Красный четный",
    id: 1,
  },
  {
    title: "красно-белый нечет",
    id: 2,
  },
  {
    title: "белый четный",
    id: 3,
  },
  {
    title: "белый нечет",
    id: 4,
  },
  {
    title: "красный четный",
    id: 5,
  },
];

const NEST: Nest<Item, string | string[]>[] = [
  {
    title: "цвет",
    key: "color",
    getter(item: Item) {
      const [color] = item.title.split(" ");
      return color === "красно-белый" ? ["красный", "белый"] : color.toLowerCase();
    },
  },
  {
    title: "четность",
    key: "odd",
    getter(item: Item) {
      return item.title.split(" ").at(-1)!;
    },
  },
];

const TEST_TREE = new TreeModel("Рут тайтл", ITEMS, NEST, []);

const treeRender = (v: Item) => {
  return <>{v.title}</>;
};

const Tree = () => {
  return (
    <TreeContextProvider value={TEST_TREE}>
      <TreeComponent title="test tree" render={treeRender} />
      <TreeSelectionDebug />
    </TreeContextProvider>
  );
};

export { Tree };
