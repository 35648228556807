import { FC, PropsWithChildren, ReactNode } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Skeleton, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

//import { ExpandButton } from "elements/expandButton/expandButton";
import { Preloader } from "features/preloader/preloader";

import { useTreeContext } from "./model/context";
import { FilterPicker } from "./filterPicker";
import { NestingSelector } from "./nestingSelector";
import { TreeList } from "./treeList";

import cn from "./tree.module.less";

type TreeProps<T> = PropsWithChildren<{
  title: string;
  className?: string;
  preloadHooks?: (() => unknown)[];
  render: (data: T) => ReactNode;
}>;

const TreeSkeleton = ({ className, title }: { className?: string; title: string }) => {
  return (
    <div className={classNames(className, cn.container)}>
      <div className={cn.head}>
        <Typography.Text>
          {title} <Skeleton.Input active size="small" />
        </Typography.Text>
      </div>
      <Skeleton.Input style={{ width: "100%" }} />
      <div className={cn["list-skeleton"]}>
        <Skeleton active />
      </div>
    </div>
  );
};

const Tree: FC<TreeProps<any>> = observer(({ title, className, preloadHooks = [], children, render }) => {
  const tree = useTreeContext();
  const filterManager = tree.filterManager;

  return (
    <Preloader hooks={preloadHooks} whileLoading={<TreeSkeleton title={title} className={className} />}>
      <div className={classNames(className, cn.container)}>
        <div className={cn.head}>
          <Typography.Text>
            {title} ({tree.selectedCount}/{tree.totalCount})
          </Typography.Text>
          {filterManager && filterManager.filters.length > 0 && (
            <FilterPicker value={filterManager.value} onChange={filterManager.setSelected} groups={filterManager.groups} />
          )}
        </div>
        {children}
        <Input
          suffix={<SearchOutlined />}
          className={cn.search}
          value={tree.keyWord ?? ""}
          onChange={(event) => tree.setKeyWord(event.target.value)}
        />
        {tree.nestingFields.length > 0 && <NestingSelector />}
        <div className={cn.list}>
          <TreeList render={render} />
        </div>
      </div>
    </Preloader>
  );
});

export { Tree };
