import type { FC } from "react";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { Projects as ProjectsComponent } from "features/projects/projects";
import { useProjects } from "models/project/projects";

import cn from "./projects.module.less";

const Projects: FC = () => (
  <Preloader hooks={[useProjects]}>
    <PageFrame title="Проекты" bodyClassName={cn.container}>
      <ProjectsComponent />
    </PageFrame>
  </Preloader>
);

export { Projects };
