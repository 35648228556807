import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { NodeType } from "models/project/fact/infrastructure/nodes";

type DRow = {
  title: string;
  startedAt: Dayjs;
  finishedAt?: Dayjs | null;
  coords: string;
  altitude: number;
  isHaveBoundaryConditions: boolean;
  remove?: () => void;
};

class ExternalSource extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data,
    coords: `X: ${this.coords.x.toFixed(2)} Y: ${this.coords.y.toFixed(2)}`,
    isHaveBoundaryConditions: this.isHaveBoundaryConditions,
    remove: this.remove,
  });

  public readonly data: NodeType;
  public isHaveBoundaryConditions: boolean = false;

  constructor(private parent: ExternalSources, data: NodeType) {
    super(parent);

    this.data = data;
    this.setIsHaveBoundaryConditions(data);

    makeObservable(this, {
      data: observable,
      isHaveBoundaryConditions: observable,
      coords: computed,
      updateValue: action,
    });
  }

  get coords() {
    return { x: this.data.x || 0, y: this.data.y || 0 };
  }

  private setIsHaveBoundaryConditions = (data: NodeType) => {
    if (data.boundaryConditions === undefined) {
      this.isHaveBoundaryConditions = false;
    } else if (data.boundaryConditions.length === 0) {
      this.isHaveBoundaryConditions = false;
    } else {
      this.isHaveBoundaryConditions = true;
    }
  };

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const [k, value] = [key as keyof NodeType, newValue as never];
    if (key === "isHaveBoundaryConditions") {
      this.isHaveBoundaryConditions = newValue;
      return [undefined, undefined];
    }
    if (key === "startedAt" || key === "title" || key === "altitude" || "finishedAt") {
      value && this.parent.infrastructure.nodes.update({ ...this.data, [k]: value });
      const prev = this.data[k];
      this.data[k] = value;
      return [prev, value];
    }
    return [undefined, undefined];
  }

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.parent.infrastructure.nodes.remove([this.data.uuid]);
    this.parent.childrenStore?.splice(this.index, 1);
  };
}

class ExternalSources extends TableNode<DRow, ExternalSource> {
  constructor(public infrastructure: Infrastructure) {
    super();

    this.initChildren();
  }

  private initChildren() {
    const nodes = this.infrastructure.nodes.data || [];
    const extenalSources = nodes.filter(({ type }) => type === "externalSource");
    this.childrenStore = new ChildrenStoreArray(
      this,
      extenalSources.map((el) => new ExternalSource(this, el))
    );
  }
}

export { type DRow as DRowExtenalSources, ExternalSources };
