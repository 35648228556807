import { isValidElement, ReactElement, type ReactNode } from "react";
import { SetURLSearchParams } from "react-router-dom";
import { SelectProps } from "antd";
import { action, computed, IObservableArray, makeObservable, observable, runInAction, transaction } from "mobx";
import { debounce } from "throttle-debounce";

import { ExternalFilterOption, Filters, type TreeFilter } from "./filters/filters";
import { SelectionManager } from "./selectionManager";
import {
  isLeafNode,
  isNestNode,
  itemPaths,
  KEY_JOIN_SYMBOL,
  Nest,
  Nested,
  nestKey,
  Required,
  TreeListNode,
  TreeNode,
  TreeNodeLeaf,
  TreeNodeNest,
} from "./types";

const isIterable = (obj: any): obj is Iterable<ReactNode> => {
  return obj != null && typeof obj[Symbol.iterator] === "function";
};

class Tree<Leaf extends Required> {
  readonly items: IObservableArray<Leaf>;
  readonly nestingFields: IObservableArray<Nest<Leaf>>;
  nestingSelection: string[] = [];
  readonly collapsed = observable.set<string>();
  public readonly filterManager?: Filters<Leaf>;
  readonly selectManager = new SelectionManager(this);
  public keyWord: string | null = null;

  private get yggdrasil(): Record<string, Nested<Leaf>[]> {
    return Object.fromEntries(
      this.items.map((item) => [
        item.id,
        this.nestingFields
          .map((nest): Nested<Leaf> | null => {
            const result = nest.getter(item);
            if (!Array.isArray(result)) {
              return null;
            } else {
              return {
                item,
                nest,
                bins: result.map((nestValue) => ({
                  key: nestKey(nest, nestValue),
                  data: nestValue,
                  title: ("render" in nest ? nest.render : (v: string) => v)(nestValue),
                })),
              };
            }
          })
          .filter(Boolean) as Nested<Leaf>[],
      ])
    );
  }

  constructor(
    private rootTitle: string | null,
    items: Leaf[],
    splitters: Nest<Leaf>[],
    filters: TreeFilter<Leaf, any>[],
    public selectable = true,
    externalFilters?: Record<string, ExternalFilterOption<Leaf>>,
    selectAll: boolean = true,
    initialLevels?: number[],
    public singleSelect?: boolean,
    private searchParams?: [URLSearchParams, SetURLSearchParams],
    defaultOpen?: Leaf
  ) {
    this.items = observable.array(items);
    this.nestingFields = observable.array(splitters);
    if (selectAll && !singleSelect) {
      this.selectAll();
    }

    if (filters) {
      this.filterManager = new Filters(filters, externalFilters);
    }

    if (initialLevels) {
      this.setSelectedLevels(initialLevels);
    }

    if (defaultOpen) {
      const openNode = this.findParent(this.tree, (node) => {
        if (isLeafNode(node)) {
          return node.data.id === defaultOpen.id;
        }
        return false;
      });
      if (openNode) {
        this.openNode(openNode);
      }
      if (singleSelect) {
        // @todo recover single select
        // this.selectManager.select(defaultOpen);
      }
    }
    this.keyWord = searchParams ? searchParams[0].get("search") ?? null : null;

    makeObservable<Tree<Leaf>, "tree">(this, {
      nestingOptions: computed,
      totalCount: computed,
      selectedCount: computed,
      selectedNests: computed,
      selectedItems: computed,
      selectedItemsId: computed,
      selectedNesting: computed,
      selected: computed,
      list: computed,
      tree: computed,
      nestingSelection: observable,
      keyWord: observable,
      setKeyWord: action,
    });
  }

  findParent(
    rootNode: TreeNodeNest<Leaf>,
    condition: (node: TreeNode<Leaf>) => boolean
  ): TreeNodeNest<Leaf> | undefined {
    for (const node of rootNode.children!) {
      if (condition(node)) {
        return rootNode;
      } else if (isNestNode(node)) {
        const res = this.findParent(node, condition);
        if (res) {
          return res;
        }
      }
    }
  }

  openNode(node: TreeNodeNest<Leaf>) {
    this.collapse(node);
    const parent = this.findParent(this.tree, (tNode) => {
      return node.key === tNode.key;
    });
    if (parent) {
      this.openNode(parent);
    }
  }

  onNestingChange: SelectProps<string>["onChange"] = (value: any) =>
    runInAction(() => {
      this.nestingSelection = value;
    });

  get nestingOptions(): Array<{
    label: React.ReactNode;
    value: string | number | null;
  }> {
    return this.nestingFields.map(({ title, key }) => ({
      value: key,
      label: title,
    }));
  }

  get totalCount(): number {
    return this.items.length;
  }

  get selectedCount(): number {
    return this.selected.size;
  }

  public get selectedNests(): number[] {
    return this.nestingFields
      .map((v, id) => ({ v, id }))
      .filter(({ v: { key } }) => this.nestingSelection.includes(key))
      .map(({ id }) => id);
  }

  nestAt(index: number): Nest<Leaf> | undefined {
    return this.nestingFields.find(({ key }) => this.nestingSelection[index] === key);
  }

  // в сложных случаях когда надо различать выбор элементов интема используем это
  public get selectedNesting(): { item: Leaf; paths: { path: Nested<Leaf>["bins"]; key: string }[] }[] {
    return [...Object.entries(this.yggdrasil)]
      .map(([id, nested]) => ({
        item: this.at(parseInt(id))!,
        paths: itemPaths(nested, parseInt(id)).filter(({ key }) => this.selectManager.selected.has(key)),
      }))
      .filter(({ paths }) => paths.length !== 0);
  }

  // если просто узнать какие элементы выбраны то нам сюда
  public get selectedItems(): Leaf[] {
    const selectedIds = new Set(this.selectedItemsId);
    return this.items.filter(({ id }) => selectedIds.has(id));
  }

  public get selectedItemsId(): Array<number> {
    return Array.from(this.selectManager.selected.keys(), (key) => parseInt(key.split(KEY_JOIN_SYMBOL).at(-1)!));
  }

  selectAll() {
    runInAction(() => {
      transaction(() => {
        for (const key of this.provideKeysIterator()) {
          this.selectManager.selected.add(key);
        }
      });
    });
  }

  provideKeysIterator = function* (this: Tree<Leaf>) {
    for (const [id, nesting] of Object.entries(this.yggdrasil)) {
      for (const { key } of itemPaths(nesting, parseInt(id))) {
        yield key;
      }
    }
  };

  get selected() {
    return this.selectManager.selected;
  }

  setSearch = debounce(1000, (keyWord: string | null) => {
    if (this.searchParams) {
      this.searchParams[1](keyWord ? { search: keyWord } : {});
    }
  });

  setKeyWord = (keyWord: string | null) => {
    this.keyWord = keyWord;
    this.setSearch(keyWord);
  };

  searchFilter = (item: Required): boolean => {
    const { title } = item;

    const checkNode = (node: ReactNode): boolean => {
      if (typeof node === "string" || typeof node === "number") {
        return node.toString().toLowerCase().includes(this.keyWord!.toLowerCase());
      } else {
        const element = node;
        if (isValidElement(element)) {
          const reactElement: ReactElement = element;
          return checkNode(reactElement.props?.children as ReactNode);
        } else if (isIterable(element)) {
          const iterable: Iterable<ReactNode> = element;
          return Array.from(iterable).some((element) => checkNode(element));
        } else {
          return false;
        }
      }
    };

    if (typeof title === "string" || typeof title === "number") {
      return title.toString()?.toLowerCase().includes(this.keyWord!.toLowerCase());
    } else if (isValidElement(title?.(item))) {
      return checkNode(title(item));
    } else {
      return true;
    }
  };

  setSelectedLevels(levels: number[]) {
    const selection: string[] = [];
    for (let i = 0; i !== this.nestingFields.length; ++i)
      if (levels.includes(i)) {
        selection.push(this.nestingFields[i].key);
      }
    this.nestingSelection = selection;
  }

  get length(): number {
    return 1;
  }

  get tree(): TreeNodeNest<Leaf> {
    const { yggdrasil } = this;
    const split = (
      items: Leaf[],
      level: number,
      currentSpreads = new Map<Leaf, { nest: Nest<Leaf>; data: any }[]>(),
      path: string = ""
    ): { children: TreeNode<Leaf>[]; count: number } => {
      const nest = this.nestAt(level - 1);
      if (nest === undefined) {
        return {
          children: items.map((data): TreeNodeLeaf<Leaf> => {
            const spreadPath = currentSpreads.get(data);
            return {
              key: [...(spreadPath?.map((v) => v.nest.key) ?? []), `${data.id}`].join(KEY_JOIN_SYMBOL),
              level,
              count: 1,
              data,
              id: data.id,
              // все пути по которым может быть заспличен элемент со всеми вариантами ключей
              nests: yggdrasil[data.id],
              // по которым фактически заспличен
              splits: spreadPath?.map(({ data, nest }) => nestKey(nest, data)) ?? [],
            };
          }),
          count: items.length + 1,
        };
      }
      const { getter } = nest;

      const bins = new Map<any, Leaf[]>();
      const spreaded = new Set<Leaf>();

      for (const item of items) {
        const itemBins = getter(item);
        if (Array.isArray(itemBins)) {
          spreaded.add(item);
        }
        for (const bin of Array.isArray(itemBins) ? itemBins : [itemBins]) {
          if (!bins.has(bin)) {
            bins.set(bin, []);
          }
          bins.get(bin)!.push(item);
        }
      }

      const children = Array.from(bins.entries(), ([data, children]): TreeNodeNest<Leaf> => {
        const deeperSpreads = new Map([...currentSpreads.entries()].map(([key, val]) => [key, [...val]]));
        for (const spreadedNode of spreaded.values()) {
          if (!deeperSpreads.has(spreadedNode)) {
            deeperSpreads.set(spreadedNode, []);
          }
          deeperSpreads.get(spreadedNode)!.push({ nest, data });
        }

        const deeperPath = `${path}${path.length ? KEY_JOIN_SYMBOL : ""}${nestKey(nest, data)}`;

        return {
          ...split(children, level + 1, deeperSpreads, deeperPath),
          key: deeperPath,
          level,
          element: ("render" in nest ? nest.render : (v: string) => v)(data),
          data: data,
        };
      });
      return {
        children,
        count: children.reduce((sum, { count }) => sum + count, 1),
      };
    };

    return {
      key: "root",
      data: this.rootTitle,
      element: this.rootTitle,
      level: 0,
      ...split(this.filteredItems, 1),
    };
  }

  at(targetId: number): Leaf | null {
    return this.items.find(({ id }) => id === targetId) ?? null;
  }

  get filteredItems(): Leaf[] {
    if (this.keyWord === null || this.keyWord === "") {
      return this.items;
    }
    return this.items.filter((item) => this.searchFilter(item));
  }

  itemsIterator(onlyCollapsed: boolean = true) {
    const me = this;

    return function* (tree: TreeNode<Leaf>): Iterable<TreeListNode<Leaf>> {
      // высылаем сам узел, не проставляем родителя, если он есть он выставится при подъеме
      yield {
        ...tree,
        parent: null,
        expand: {
          status: !me.isCollapsed(tree),
          toggleExpand: () => (isLeafNode(tree) ? undefined : me.collapse(tree)),
        },
        selectionManager: me.selectManager,
      };
      if (
        isNestNode(tree) &&
        // если расхлопнута или надо показать все
        (!me.isCollapsed(tree) || onlyCollapsed === false)
      ) {
        for (const node of tree.children) {
          // не попадает под фильтрацию
          if (me.keyWord === null || me.keyWord === "" || isNestNode(node) || me.searchFilter(node.data as Required)) {
            // то считаем каждого
            let isFirst = true;
            for (const itm of me.itemsIterator()(node)) {
              if (isFirst) {
                yield {
                  ...itm,
                  expand: {
                    status: !me.isCollapsed(itm),
                    toggleExpand: () => (isLeafNode(itm) ? undefined : me.collapse(itm)),
                  },
                  parent: tree,
                };
                isFirst = false;
              } else {
                yield itm;
              }
            }
          }
        }
      }
    };
  }

  get list(): TreeListNode<Leaf>[] {
    return [...this.itemsIterator()(this.tree)];
  }

  get listExpanded(): TreeListNode<Leaf>[] {
    return [...this.itemsIterator(false)(this.tree)];
  }

  collapse = (item: TreeNode<Leaf>, value?: boolean) => {
    const { key } = item;
    this.collapsed[value ?? this.isCollapsed(item) ? "delete" : "add"](key);
  };

  isCollapsed(node: TreeNode<Leaf>): boolean {
    return this.collapsed.has(node.key);
  }
}

export { type Nest, type Required, Tree, type TreeListNode };
