import { FC } from "react";
import { observer } from "mobx-react";

import { TreeContextProvider } from "elements/tree/model/context";
import { Tree } from "elements/tree/tree";

import { useProjectsContext } from "./projectsContextProvider";

import cn from "./tppList.module.less";

const TppList: FC = observer(() => {
  const fields = useProjectsContext();

  return (
    <TreeContextProvider value={fields.tree}>
      <div className={cn["tpp-list"]}>
        <Tree render={(v) => v.title} className={cn.tree} title="Список ТПП" />
      </div>
    </TreeContextProvider>
  );
});

export { TppList };
