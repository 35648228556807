import { FC } from "react";
import { Button, DatePicker, Divider } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";

import { Icon } from "elements/icon/icon";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { CalculateStore } from "models/project/fact/infrastructure/calculateStore";

import "dayjs/locale/ru";

import { ReactComponent as ResetIcon } from "./reset.svg";

import cn from "./infrastructureResultsTable.module.less";

const { RangePicker } = DatePicker;

const SubFrameTitle: FC<{ store: CalculateStore }> = observer(({ store }) => {
  if (store.hydraulicDataCollection === null) {
    return <></>;
  }
  return (
    <div className={cn.subFrameTitle}>
      <RangePicker
        className={cn.date}
        locale={locale}
        placeholder={["Начало", "Конец"]}
        picker="month"
        value={store.userFilterParams?.date}
        onChange={(dates) => {
          if (dates) {
            const [start, end] = dates;
            store.setUserFilterParams({ date: [dayjs(start), dayjs(end)] });
          }
        }}
      />
      <Button icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />} onClick={store.resetUserFiterDate} />
      <Divider type="vertical" />
      <div className={cn.inputCustomWrapper}>
        <div>Макс. градиент давления</div>
        <InputNumber
          bordered
          placeholder="500 атм/км"
          value={store.userFilterParams?.pressureGradient ?? undefined}
          onUpdate={(value) => store.setUserFilterParams({ pressureGradient: value })}
        />
      </div>
      <div className={cn.inputCustomWrapper}>
        <div>Макс. скорость потока</div>
        <InputNumber
          bordered
          placeholder="1 м/сек"
          value={store.userFilterParams?.velocity ?? undefined}
          onUpdate={(value) => store.setUserFilterParams({ velocity: value })}
        />
      </div>
      <Button icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />} onClick={store.resetUserFiterInputs} />
    </div>
  );
});

export { SubFrameTitle };
