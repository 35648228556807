import { FC } from "react";
import { Tooltip, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Icon } from "elements/icon/icon";

import { ReactComponent as FlagIcon } from "../icons/flag.svg";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { StatusIcon } from "../icons/status/status";
import { useTechForecastModel } from "../useTechForecastModel";

import { WellTooltip } from "./wellTooltip";

import cn from "./wellItem.module.less";

type WellItemProps = {
  index: number;
  groupIndex: number;
};

const TOOLTIP_OVERLAY_STYLE = { maxWidth: "350px" };

const WellItem: FC<WellItemProps> = observer(({ index: collapsedIndex, groupIndex: rawGroupIndex }) => {
  const model = useTechForecastModel();
  const groupIndex = model.wells.groupAt(rawGroupIndex);
  const index = model.recoverIndexToUnCollapsed(collapsedIndex, groupIndex);
  const event = model.wells.atEvent(index, groupIndex);
  if (event === undefined) {
    /* virtuoso передаётся новый размер групп при колапсе но он заказывает
    рендер элементов, которые уже вышли из области видимости, тем не менее
    не отображая их. Это обработчик такого случая*/
    return <div style={{ height: 1, width: 1 }} />;
  }
  const { computationStatus, paramsStatus } = model.forecast(event);

  return (
    <div className={classNames(cn.item, model.currentSelection === event && cn.selected)} onClick={model.selectedHolder(event)}>
      <Icon content={<FlagIcon />} viewBox="0 0 12 14" className={cn["flag-icon"]} />
      <Typography.Text className={cn.label}>
        {event.well.title}/{[event.intervention?.typeName, event.well.type].filter((v) => v).join(" ")}
        {event.stratumId !== null && groupIndex === 0 && `(${event.stratum?.title})`}
      </Typography.Text>
      <StatusIcon>{computationStatus}</StatusIcon>
      <StatusIcon>{paramsStatus}</StatusIcon>
      <Tooltip overlayStyle={TOOLTIP_OVERLAY_STYLE} color="#FFF" title={<WellTooltip well={event.well} />}>
        <div>
          <Icon content={<InfoIcon />} viewBox="0 0 16 16" className={cn["info-icon"]} />
        </div>
      </Tooltip>
      <DeleteButton className={cn.remove} onClick={model.removeHolder(index)} />
    </div>
  );
});

export { WellItem };
