import type { FC } from "react";

import { Icon } from "elements/icon/icon";
import { SharedIconProps } from "elements/icons/sharedIconProps";

const ASSET: FC<{ modified: boolean }> = ({ modified }) => {
  const color = modified ? "#DDDDDD" : undefined;
  const crossColor = modified ? "#DDDDDD" : "#DB4432";

  return (
    <g style={{ fill: "none", color }}>
      <path d="M13 7H3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21 17H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.99999 19.5C9.3807 19.5 10.5 18.3807 10.5 17C10.5 15.6193 9.3807 14.5 7.99999 14.5C6.61927 14.5 5.49999 15.6193 5.49999 17C5.49999 18.3807 6.61927 19.5 7.99999 19.5Z"
        fill="currentColor"
        stroke="white"
      />
      <g clip-path="url(#clip0_13773_5408)" stroke={crossColor}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9613 11L13.2174 4.06774L14.2561 3L21 9.93226L19.9613 11Z" fill={crossColor} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0387 11L20.7826 4.06774L19.7439 3L13 9.93226L14.0387 11Z" fill={crossColor} />
      </g>
      <defs>
        <clipPath id="clip0_13773_5408">
          <rect width="8" height="8" fill="white" transform="translate(13 3)" />
        </clipPath>
      </defs>
    </g>
  );
};

const DeletePeriods: FC<SharedIconProps & { modified?: boolean }> = ({ modified = false, ...props }) => (
  <Icon {...props} viewBox="0 0 24 24" content={<ASSET modified={modified} />} />
);

export { DeletePeriods };
