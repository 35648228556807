import { Format } from "elements/format/format";
import { Nest } from "elements/tree/model/tree";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { ProducingObject } from "models/project/producingObject/producingObject";

const TREE_NESTING_FIELDS: Nest<Well>[] = [
  {
    key: "mine",
    title: "Куст",
    getter: (w: Well) => w.pad,
    render: (pad: any) => <Format>{pad ? pad.title : pad}</Format>,
  },
  {
    key: "licenseRegion",
    title: "Лицензионный участок",
    getter: (w: Well) => w.licenseRegion,
    render: (lr: any) => <Format>{lr ? lr.title : lr}</Format>,
  },
  {
    key: "fond",
    title: "Фонд",
    getter: (w: Well) => w.fond,
    render: (fond: any) => <Format>{{ New: "Новый", Base: "Базовый" }[fond as "Base" | "New"]}</Format>,
  },
];

const NULL_PRODUCING_OBJECT = {
  title: "Остальные",
} as const;

const producingObjectNestingField = (fc: Forecast | null): Nest<Well> => {
  let getter;
  if (fc === null) {
    getter = (w: Well) => {
      const prodObjects = w.producingObjects;
      return prodObjects?.length ? prodObjects : [NULL_PRODUCING_OBJECT];
    };
  } else {
    getter = (w: Well) => {
      const gtms = fc.interventions.getInterventionsByWellId(w.id);
      const gtmsProducingObjects = gtms.map((g) => g.producingObject).filter((po): po is ProducingObject => !!po);
      const prodObjects = [...new Set([...(w.producingObjects ?? []), ...gtmsProducingObjects])];
      return prodObjects?.length ? prodObjects : [NULL_PRODUCING_OBJECT];
    };
  }
  return {
    key: "producingObject",
    title: "Объект разработки",
    getter,
    render: (pd: any) => <Format>{pd ? pd.title : pd}</Format>,
  };
};

export { producingObjectNestingField, TREE_NESTING_FIELDS };
