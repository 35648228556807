import { ReactNode, useState } from "react";
import { HighlightOutlined, WarningOutlined } from "@ant-design/icons";
import { ActionType } from "@rc-component/trigger";
import { Button, Popover } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";

import { DebugZone } from "elements/debugZone/debugZone";

import cn from "./alertPopover.module.less";

type ValidationMessageType = "error" | "warning" | "debug";

const typeIcons: Record<ValidationMessageType, ReactNode> = {
  error: <WarningOutlined style={{ color: "red" }} />,
  warning: <WarningOutlined style={{ color: "orange" }} />,
  debug: <HighlightOutlined />,
} as const;

const typeTitles: Record<ValidationMessageType, string> = {
  error: "Ошибка",
  warning: "Предупреждение",
  debug: "Debug-сообщение",
};

type MessageType = {
  type: ValidationMessageType;
  title?: string;
  text: string;
};

function Message<T extends MessageType>({ message }: { message: T }) {
  const title = message.title;
  const text = message.text.split("\n").map((par, j) => <div key={j}>{par}</div>);

  return (
    <div className={cn["message"]}>
      <div className={cn["message-header"]}>
        <div>{typeIcons[message.type]}</div>
        <div>{title ?? typeTitles[message.type]}</div>
      </div>
      <div>{message.type === "debug" ? <DebugZone>{text}</DebugZone> : text}</div>
    </div>
  );
}

function MessageDisplay<T extends MessageType>({ messages }: { messages: T[] }) {
  return (
    <div className={cn["popover-content"]}>
      <div className={cn["message-wrapper"]}>
        {messages.map((message, messageIndex) => {
          return <Message key={messageIndex} message={message} />;
        })}
      </div>
    </div>
  );
}

type Props<T> = {
  messages?: T[];
  btnIcon: ReactNode;
  trigger?: ActionType;
  placement?: TooltipPlacement;
  title?: string;
};

function AlertPopover<T extends MessageType>({ messages, btnIcon, trigger = "click", placement = "bottom", title }: Props<T>) {
  const [isOpenedDetails, setOpenedDetails] = useState<boolean>(false);

  const switchOpenDetails = () => {
    setOpenedDetails((v) => !v);
  };

  if (!messages) return null;

  return (
    <Popover
      trigger={trigger}
      content={<MessageDisplay messages={messages} />}
      placement={placement}
      title={title ? title : "Список ошибок и предупрежений"}
      open={isOpenedDetails}
      onOpenChange={switchOpenDetails}
    >
      <Button icon={btnIcon} />
    </Popover>
  );
}

export { type MessageType as AlertMessageType, AlertPopover, typeIcons as alertPopoverTypeIcons };
