import { UploadFile } from "antd";
import { objectToCamel } from "ts-case-convert";

import { convertPressure } from "utils/convertePressure";
import { req, reqCamel } from "utils/request";

import { reqLogged } from "../logger";

import { PipeBoundaryCondition, PipeSystem } from "./types";

const { barToAtm, atmToBar } = convertPressure;

async function getPipeSystem(scenarioId: number): Promise<PipeSystem | null> {
  try {
    const response = await reqCamel.get<PipeSystem>(`pipe/${scenarioId}`);
    if (typeof response === "object" && response !== null && "detail" in response) {
      throw Error(response.detail as string);
    }
    return response;
  } catch (error) {
    return null;
  }
}

type BoundaryCondition = { boundaryCondition: { pressure: number } } & Omit<PipeBoundaryCondition, "boundaryCondition">;

async function getPipeBoundaryCondition(scenarioId: number): Promise<BoundaryCondition[]> {
  const response = await reqCamel.get<BoundaryCondition[]>(`pipe_boundary_condition/${scenarioId}`);
  return response.map((el) => ({
    ...el,
    boundaryCondition: { pressure: barToAtm(el.boundaryCondition.pressure) ?? 0 },
  }));
}

async function savePipeSystem(scenarioId: number, pipeSystem: PipeSystem): Promise<void> {
  await reqCamel.post("pipe/", { ...pipeSystem, scenarioId }).catch(() => {});
}

async function updatePipeBoundaryCondition(scenarioId: number, params: PipeBoundaryCondition[]) {
  await reqLogged.post(
    `pipe_boundary_condition/${scenarioId}`,
    params.map((el) => ({
      ...el,
      boundaryCondition: { ...el.boundaryCondition, pressure: atmToBar(el.boundaryCondition?.pressure) ?? 0 },
    }))
  );
}

async function deletePipeSystem(scenarioId: number): Promise<void> {
  await reqCamel.delete(`pipe/${scenarioId}`).catch(() => {});
}

async function getOisPipeSystemOrMines(scenarioId: number, isMinesOnly?: boolean): Promise<PipeSystem | null> {
  const result = await reqCamel.get<PipeSystem>(
    `pipe/ois-pipe/${scenarioId}${isMinesOnly ? "?is_mines_only=true" : ""}`
  );
  return { ...result, linksMineNode: result.linksMineNode.map((el) => ({ ...el, isFactual: true })) };
}

type BoundaryConditionType = {
  date: string | null;
  oilRate: number | null;
  waterRate: number | null;
};

async function parseExcelBoundaryConditions(file: UploadFile): Promise<BoundaryConditionType[]> {
  const formData = new FormData();
  formData.append("file", file as unknown as Blob);
  try {
    const response = await req.post<BoundaryConditionType[]>(`pipe/parse_excel_pipe/`, formData);
    return response.map(objectToCamel);
  } catch (err) {
    throw new Error("invalid file format");
  }
}

export {
  deletePipeSystem,
  getOisPipeSystemOrMines,
  getPipeBoundaryCondition,
  getPipeSystem,
  parseExcelBoundaryConditions,
  savePipeSystem,
  updatePipeBoundaryCondition,
};

export type { BoundaryConditionType };
