import { FC } from "react";
import { observer } from "mobx-react";

import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { Debet as DebetStore } from "models/project/fact/forecast/techPrediction/techPrediction";

import { DeletePeriods } from "./icons/deletePeriods";

const DeletePeriodsButton: FC<{ store: DebetStore }> = observer(({ store }) => {
  const isNotSelected = store.selectedEvents.length === 0;
  const uniqueSelectedCount = new Set(store.selectedEvents.map((event) => event.well.id)).size;

  const deletePeriodsTooltip = isNotSelected
    ? "Не выбраны скважины для удаления пересекающихся периодов добычи"
    : `Удалить пересекающиеся периоды добычи для выбранных ${uniqueSelectedCount} скважин`;

  return (
    <ToolbarButton
      tooltip={{ title: deletePeriodsTooltip }}
      popconfirm={{
        title: "Удаление периодов добычи",
        description: `${deletePeriodsTooltip}?`,
        onConfirm: () => store.deleteOverlapsPeriod(),
        onCancel: () => "",
        okText: "Да",
        cancelText: "Нет",
      }}
      type="text"
      disabled={isNotSelected}
      icon={<DeletePeriods width="20" height="20" modified={isNotSelected} />}
    />
  );
});

export { DeletePeriodsButton };
