import { useRef } from "react";
import { ColumnsType } from "antd/es/table";
import { UploadFile } from "antd/es/upload";

import { ImportModalContent, ImportModalResolve, TableState } from "elements/importModalContent/ImportModalContent";
import { useModal } from "elements/modal/modal";
import { Column } from "features/tableDebug/simpleTable";
import { parseExcelBoundaryConditions } from "services/back/infrastructure/infrastructure";

import { BoundaryConditions } from "./boundaryConditions";
import { BOUNDARY_CONDITIONS_TEMPLATE_INFO_CARDS } from "./infocards";

type MockDataType = {
  id: number;
  date: number | string;
  oilRate: string;
  waterRate: string;
};

const MOCK_DATA_TABLE: MockDataType[] = [
  {
    id: 1,
    date: "08.2029",
    oilRate: "739,96",
    waterRate: "99,88",
  },
  {
    id: 2,
    date: "03.2027",
    oilRate: "614,39",
    waterRate: "53,03",
  },
  {
    id: 3,
    date: "08.2024",
    oilRate: "850,07",
    waterRate: "89,41",
  },
];

const columnsMock: ColumnsType<MockDataType> = [
  {
    dataIndex: "date",
    title: "Месяц",
    width: 100,
  },
  {
    dataIndex: "oilRate",
    title: "Расход нефти, т/сут",
    width: 104,
  },
  {
    dataIndex: "waterRate",
    title: "Расход воды, т/сут",
    width: 104,
  },
];

const columns: Column[] = [
  {
    title: "Месяц",
    dataKey: "date",
    type: "string",
  },
  {
    title: "Расход нефти, т/сут",
    dataKey: "oilRate",
    type: "number",
  },
  {
    title: "Расход воды, т/сут",
    dataKey: "waterRate",
    type: "number",
  },
];

const useImportBoundaryConditionsModal = (): (() => Promise<ImportModalResolve | null>) => {
  const showModal = useModal<ImportModalResolve, TableState<any>>();

  const parseExcel = async (file: UploadFile): Promise<any> => {
    try {
      const resolve = await parseExcelBoundaryConditions(file);
      return new BoundaryConditions(resolve);
    } catch (err) {
      throw new Error("invalid file format");
    }
  };

  const val: { current: TableState<any> } = useRef({
    columns,
    parseExcel,
    importBtn: "Импортировать",
    template: {
      infoCards: BOUNDARY_CONDITIONS_TEMPLATE_INFO_CARDS,
      templateLink: (
        <a href="/static/boundary_conditions_template.xlsx" download="boundary_conditions_template.xlsx">
          Скачать шаблон
        </a>
      ),
      data: MOCK_DATA_TABLE,
      columns: columnsMock,
    },
  });

  return async () => {
    return showModal(ImportModalContent, val, "Импорт граничных условий для внешнего источника", null, null, "700px", null)
      .then((data) => data)
      .catch(() => null);
  };
};

export { useImportBoundaryConditionsModal };
