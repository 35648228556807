import { Dayjs } from "dayjs";

import { ChannelInfo } from "elements/charts/channelsManager";
import { UOM } from "elements/uom";
import { TechSummaryAxisData } from "features/techSummaryChart/techSummaryChart";
import { global } from "models/global";
import { aggregateByDate } from "models/project/fact/production/aggregate";
import { Production, ProductionDatum } from "models/project/fact/production/production";
import { WellsFondsByYear } from "services/back/wellStatus";
import { transposeArrayObject } from "utils/itertools";
import { Range } from "utils/range";

import { defaultYearDatum, PARAMS, YearDatum } from "./constants";

async function* zipAggregate(
  selected: { wellId: number; producingObjectsIds?: number[] }[],
  productionStore: Production
): AsyncGenerator<[Dayjs, YearDatum]> {
  const productions = selected.map((s) => productionStore.wholeWellData(s.wellId, false, s.producingObjectsIds));
  const decemberProductions = selected.map((s) => productionStore.wholeWellData(s.wellId, true, s.producingObjectsIds));

  const fondGen = aggregateByDate(aggregateFond, decemberProductions);
  const aggregatedGen = aggregateByDate(aggFunc, productions);

  let fondIt = fondGen.next();
  let aggIt = aggregatedGen.next();
  while (!fondIt.done && !aggIt.done) {
    const [year, datum] = aggIt.value;
    const [, fond] = fondIt.value;

    datum.miningFond = fond.miningFond;
    datum.injectionFond = fond.injectionFond;

    await new Promise((resolve) => setTimeout(resolve, 10));
    yield [year, datum];

    fondIt = fondGen.next();
    aggIt = aggregatedGen.next();
  }
}

function summarizeBaseFond(
  wellsIds: number[],
  baseFonds: WellsFondsByYear
): { [year: number]: { inj: number; prod: number } } {
  const result: any = {};
  for (const id of wellsIds) {
    const wellFonds = baseFonds[id];
    if (wellFonds !== undefined) {
      for (const [year, fonds] of Object.entries(wellFonds) as Iterable<[number, WellsFondsByYear[number][number]]>) {
        if (!result[year]) {
          result[year] = { prod: 0, inj: 0 };
        }
        const yearInfo = result[year];
        if (fonds.prod) {
          yearInfo.prod++;
        }
        if (fonds.inj) {
          yearInfo.inj++;
        }
      }
    }
  }
  return result;
}

async function aggregate(
  yearsRange: Range,
  selected: { wellId: number; producingObjectsIds?: number[] }[],
  productionStore: Production,
  fcStartYear: number,
  baseFonds: WellsFondsByYear
): Promise<YearDatum[]> {
  const baseFondsAggregated = summarizeBaseFond(
    selected.map((s) => s.wellId),
    baseFonds
  );
  const data = yearsRange.array.fill(null).map(() => defaultYearDatum(null));
  const accum = {
    oil: 0,
    liquid: 0,
    waterInj: 0,
  };
  for await (const [year, datum] of zipAggregate(selected, productionStore)) {
    const curYear = year.year();
    accum.oil += datum.oilProd ?? 0;
    accum.liquid += datum.liquidProd ?? 0;
    accum.waterInj += datum.waterInj ?? 0;

    if (year.year() < fcStartYear) {
      datum.miningFond = baseFondsAggregated[year.year()].prod;
      datum.injectionFond = baseFondsAggregated[year.year()].inj;
    }

    if (!yearsRange.includes(curYear)) {
      continue;
    }

    datum.accumOilProd = accum.oil;
    datum.accumLiquidProd = accum.liquid;
    datum.accumWaterInj = accum.waterInj;

    datum.accumInjectCompensation = accum.liquid && accum.waterInj / accum.liquid;

    data[yearsRange.id(curYear)] = datum;
  }

  return data;
}

function yearDatumsToAxes(datums: YearDatum[]): { lines: TechSummaryAxisData[]; bars: TechSummaryAxisData } {
  const transposed = transposeArrayObject(datums, defaultYearDatum(null))!;

  const by_unit = new Map<string, [string, ChannelInfo][]>();
  Object.entries(PARAMS).forEach((param) => {
    by_unit.get(param[1].axis ?? "unknown")?.push(param) ?? by_unit.set(param[1].axis ?? "unknown", [param]);
  });

  const BAR_UNIT = new UOM(12, 1, global.uomResolver).unit;

  const bars: TechSummaryAxisData = {
    unit: "Скважины, шт",
    side: "right",
    dataSet: (by_unit.get(BAR_UNIT ?? "скв") ?? []).map((param) => ({
      channel: param[0],
      y: transposed[param[0] as keyof YearDatum],
      title: param[1].title,
    })),
  };
  by_unit.delete(BAR_UNIT ?? "скв");

  const lines: TechSummaryAxisData[] = [];
  for (const [unit, params] of by_unit) {
    lines.push({
      unit: unit,
      side: 2 * lines.length < by_unit.size ? "left" : "right",
      dataSet: params.map((param) => ({
        y: transposed[param[0] as keyof YearDatum],
        title: param[1].title,
        channel: param[0],
      })),
    });
  }

  return { lines, bars };
}

const aggFunc = (datums: ProductionDatum[]): YearDatum => {
  const result = defaultYearDatum(0);

  for (const datum of datums) {
    result.oilProd += datum.oil_prod ?? 0;
    result.liquidProd += datum.liquid_prod ?? 0;
    result.waterInj += datum.water_inj ?? 0;
    result.prodDays += datum.prod_days ?? 0;
    result.injDays += datum.inj_days ?? 0;
  }
  result.waterCut = (1 - result.oilProd / result.liquidProd) * 100 || 0;
  result.oilRate = (result.oilProd * 1000) / result.prodDays || 0;
  result.liquidRate = (result.liquidProd * 1000) / result.prodDays || 0;
  result.injectionCapacity = (result.waterInj * 1000) / result.injDays || 0;
  result.injectCompensation = result.liquidProd && result.waterInj / result.liquidProd;

  return result;
};

const aggregateFond = (datums: ProductionDatum[]): Pick<YearDatum, "miningFond" | "injectionFond"> => {
  let { miningFond, injectionFond } = defaultYearDatum(0);
  for (const datum of datums) {
    if ((datum.inj_days ?? 0) > 0 && (datum.water_inj ?? 0) > 0) {
      ++injectionFond;
    }
    if ((datum.prod_days ?? 0) > 0 && (datum.oil_prod ?? 0) > 0) {
      ++miningFond;
    }
  }
  return { miningFond, injectionFond };
};

export { aggregate, yearDatumsToAxes };
