import { makeAutoObservable, observable } from "mobx";

import type { Tree } from "./tree";
import { isNestNode, KEY_JOIN_SYMBOL, type Required, selectionKeys, type TreeNode } from "./types";

function* nodeKeys<T extends Required>(data: TreeNode<T>): Generator<string, void, undefined> {
  if (isNestNode(data)) {
    for (const child of data.children) {
      yield* nodeKeys(child);
    }
  } else {
    yield* selectionKeys(data).values();
  }
}

class SelectionManager<Leaf extends Required> {
  /*
  Идея в том, что выбрать в стейте можно только узел так, будто он заспличен по всем возможным путям.
  Выбирая элемент в реальном дереве мы выбираем все пути, по которым он не заспличен.
  При проверке выбранности реального элемента мы проверяем выборы всех незаспличенных потомков.
  У листа есть все пути обхода, по ним конструируются ключи всех выборов. Среди них оставляются
  все объединённые в данном узле. Это ключевая операция. Далее с ними осуществляются все необходимые операции */
  readonly selected = observable.set<string>();

  get isRootSelected(): boolean | "partially" {
    return this.isSelectedNode(this.tree.tree);
  }

  isSelectedNode<T extends Required>(data: TreeNode<T>): boolean | "partially" {
    let result: undefined | boolean;
    for (const key of nodeKeys(data)) {
      const childState = this.selected.has(key);
      result = result ?? childState;
      if (result !== childState) {
        return "partially";
      }
    }
    return result ?? true;
  }

  select = <T extends Required>(data: TreeNode<T>, state?: boolean) => {
    if (this.isSingleSelect) {
      this.selected.clear();
    }
    const finalState = state ?? this.isSelectedNode(data);
    for (const key of nodeKeys(data)) {
      this.selected[finalState ? "delete" : "add"](key);
    }
  };

  get selectedId(): number | undefined {
    console.assert(this.isSingleSelect);
    if (this.selected.size === 0) {
      return undefined;
    }
    const id = parseInt(this.selected.values().next().value.split(KEY_JOIN_SYMBOL).at(-1));
    if (isFinite(id)) {
      return id;
    }
    return undefined;
  }

  set selectedId(value: number | undefined) {
    console.assert(this.isSingleSelect);
    this.selected.clear();
    if (value !== undefined) {
      this.selected.add(`${value}`);
    }
  }

  selectToggle = <T extends Required>(data: TreeNode<T>) => {
    this.select(data, this.isSelectedNode(data) !== true);
  };

  get isSingleSelect(): boolean {
    return !!this.tree.singleSelect;
  }

  constructor(private tree: Tree<Leaf>) {
    makeAutoObservable(this);
  }
}

export { SelectionManager };
