import { CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Typography } from "antd";
import { CheckboxGroupProps } from "antd/es/checkbox/Group";
import { observer } from "mobx-react";

import cn from "./settingsChart.module.less";

const DEFAULT_CHART_SETTINGS = ["1"];

const SettingsChart = observer(({ onClose, onChange }: { onClose: () => void; onChange: (checkedValue: CheckboxGroupProps["value"]) => void }) => {
  return (
    <div className={cn.container}>
      <div className={cn.header}>
        <Typography.Title className={cn.title} level={3}>
          Настройка графика
        </Typography.Title>
        <Button icon={<CloseOutlined />} onClick={onClose}></Button>
      </div>
      <main className={cn.body}>
        <Checkbox.Group className={cn.group} onChange={onChange} defaultValue={DEFAULT_CHART_SETTINGS}>
          <Checkbox disabled value="1">
            CAPEX
          </Checkbox>
          <Divider className={cn.divider}></Divider>
          <Checkbox value="2">OPEX</Checkbox>
          <Checkbox value="3">Налоги</Checkbox>
          <Checkbox value="4">НДПИ нефть</Checkbox>
          <Checkbox value="5">Налог на имущество</Checkbox>
          <Checkbox value="6">Налог на прибыль</Checkbox>
          <Checkbox value="7">НДД</Checkbox>
          <Checkbox value="8">Выручка</Checkbox>
        </Checkbox.Group>
      </main>
    </div>
  );
});

export { DEFAULT_CHART_SETTINGS, SettingsChart };
