import { FC } from "react";
import { Tooltip } from "antd";

import { Icon } from "elements/icon/icon";

import { ReactComponent as Computing } from "./computing.svg";
import { ReactComponent as Empty } from "./empty.svg";
import { ReactComponent as Fixed } from "./fixed.svg";
import { ReactComponent as Loaded } from "./loaded.svg";
import { ReactComponent as NotSaved } from "./notSaved.svg";
import { ReactComponent as NotViewed } from "./notViewed.svg";
import { ReactComponent as Ok } from "./ok.svg";
import { ReactComponent as Saved } from "./saved.svg";
import { ReactComponent as Warn } from "./warn.svg";
import { ReactComponent as Wrong } from "./wrong.svg";

const ICON_MAP = {
  //[ Расчет не проверен на физичность ] // Проверка корректности результата расчета не проведена, так как расчет еще не выполнялся. Такой статус, в том числе, отображается, когда расчет еще выполняется или когда получена ошибка расчета
  empty: {
    icon: <Empty />,
    title: "Проверка корректности результата расчета не проведена",
  },
  //[ Корректно ] // Расчет выполнен и по результатам проверки физичности является корректным
  ok: {
    icon: <Ok />,
    title: "Расчет выполнен и по результатам проверки физичности является корректным",
  },
  //[ Не корректно ]// Расчет выполнен и по результатам проверки физичности является не корректным. Тултип при наведении указывает какие именно параметры не корректны
  warn: {
    icon: <Warn />,
    title: "Расчет выполнен и по результатам проверки физичности является не корректным",
  },
  //[ Скорректирован ]// Расчет выполнен с использованием функции автокорректировки и является корректным
  fixed: {
    icon: <Fixed />,
    title: "Расчет выполнен с использованием функции автокорректировки",
  },
  //[ Расчет выполнялся ранее ] // Расчет проводился ранее, в текущей сессии никто не менял настройки
  loaded: {
    icon: <Loaded />,
    title: "Расчет проводился ранее",
  },
  //[ Расчет выполнен, но не просмотрен ] // Расчет выполнен, но не просмотрен
  notViewed: {
    icon: <NotViewed />,
    title: "Расчет выполнен, но не просмотрен",
  },
  //[ Расчет выполнен, но не сохранен ] // Расчет выполнен, но не сохранен
  notSaved: {
    icon: <NotSaved />,
    title: "Расчет выполнен, но не сохранен",
  },
  //[ Ошибка при расчете ] // При выполнении прогноза произошла ошибка солвера
  wrong: {
    icon: <Wrong />,
    title: "При выполнении прогноза произошла ошибка",
  },
  //[ Расчет выполнен и сохранен ] // Настройки расчета сохранены, прогноз построен, просмотрен и сохранён
  saved: {
    icon: <Saved />,
    title: "Настройки расчета сохранены, прогноз построен, просмотрен и сохранён",
  },
  //[ Расчет выполняется ] // Идет расчет
  computing: {
    icon: <Computing />,
    title: "Идет расчет",
  },
} as const;

type ComputationStatus = "empty" | "ok" | "warn" | "fixed" | "computing";
type ParamsStatus = "empty" | "loaded" | "notViewed" | "notSaved" | "wrong" | "saved" | "computing";

const StatusIcon: FC<{ children: keyof typeof ICON_MAP }> = ({ children }) => {
  const { icon, title } = ICON_MAP[children];
  return (
    <Tooltip title={title}>
      <div>
        <Icon width="16" height="16" content={icon} viewBox="0 0 16 16"></Icon>
      </div>
    </Tooltip>
  );
};

export { StatusIcon };
export type { ComputationStatus, ParamsStatus };
