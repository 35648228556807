import { Tooltip } from "antd";
import { DefaultOptionType } from "antd/es/select";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, runInAction, transaction, when } from "mobx";
import hash from "object-hash";

import { TechMode } from "elements/modeSelector/modeSelectorModel";
import { ForecastMode, methodSelector } from "services/back/techForecast/request";
import { StopCriterion as StopCriterionDump } from "services/back/techForecast/request";
import { WellFactProduction } from "services/back/techForecast/techForecast";
import { conditionally, conditionallyArr } from "utils/conditionally";

import { isScalarDump, Methods, MethodsSave } from "./methods";
import { ProdTimeRatio } from "./prodTimeRatio";
import { StopCriterion } from "./stopCriterion";
import { FORECAST_MODES, KNOWN_GROUPS } from "./wellTechChartChannelsMeta";
import type { WellTechForecast } from "./wellTechForecast";

function applyAssetsValidation(option: DefaultOptionType, mode: ForecastMode, recoverable?: number | null): DefaultOptionType {
  if (option.value === "dc_water_cut_vol" && !recoverable && mode === "waterCut") {
    return {
      ...option,
      label: <Tooltip title="Не заданы извлекаемые запасы">{option.label}</Tooltip>,
      disabled: true,
    };
  }
  return option;
}

type DateDomain = { min: number; max: number };

const nearest = (indexCandidate: number, data: Array<number | null>) => {
  const idxDraft = Math.round(indexCandidate);
  if (idxDraft < 0) {
    return data.findIndex((v) => v !== null);
  }
  if (idxDraft >= data.length) {
    // findLastIndex
    let i = data.length - 1;
    while (i >= 0 && data[i] === null) {
      --i;
    }
    return i;
  }
  let r = 0;
  while (data[idxDraft + r] !== undefined || data[idxDraft - r] !== undefined) {
    if (data[idxDraft + r] !== null) {
      return idxDraft + r;
    }
    if (data[idxDraft - r] !== null) {
      return idxDraft - r;
    }
    r += 1;
  }
  return data.findIndex((v) => v !== null);
};

type SettingsSave = {
  liquid: MethodsSave;
  oil: MethodsSave;
  waterCut: MethodsSave;
  metricForCompute: ForecastMode;
  stopCriterion: StopCriterionDump;
  prodTimeRatio: null | number;
  beginDate: string;
  isCorrectionEnabled: boolean;
  correctionValue: number | null;
};

class WellTechForecastSettings {
  public readonly stopCriterion = new StopCriterion();

  public readonly prodTimeRatio = new ProdTimeRatio();

  public readonly liquid;
  public readonly oil;
  public readonly waterCut;

  public beginDate: Dayjs;

  public metricForCompute: ForecastMode = "waterCut";

  public isCorrectionEnabled: boolean = false;
  public correctionValue: number | null = 0.01;

  constructor(private readonly fc: WellTechForecast) {
    this.beginDate = this.fc.manager.actualStateDate.set("day", 15);
    this.liquid = new Methods("liquid", fc);
    this.oil = new Methods("oil", fc);
    this.waterCut = new Methods("waterCut", fc);
    makeObservable(this, {
      metricForCompute: observable,
      beginDate: observable,
      isCorrectionEnabled: observable,
      correctionValue: observable,
      isValid: computed,
      dump: computed,
      mode: computed,
      save: action,
      saved: computed,
      isSaved: computed,
      isHaveManualBindings: computed,
      defaultDomain: computed,
    });
    when(() => this.saved !== undefined && this.saved !== null).then(() => {
      this.fromSave(this.saved!);
    });
    when(() => Boolean(this.fc.fact.data)).then(() => {
      const { defaultDomain } = this;
      runInAction(() =>
        transaction(() => {
          for (const mode of FORECAST_MODES) {
            this[mode].factDomain = defaultDomain!;
          }
        })
      );
    });
  }

  get defaultDomain() {
    const { data } = this.fc.fact;
    if (data === null || data === undefined) {
      return data;
    }
    const { defaultStartStep, defaultEndStep, steps } = data.factProduction;
    return {
      min: steps.findIndex((v) => v === defaultStartStep),
      max: steps.findIndex((v) => v === defaultEndStep),
    };
  }

  get isHaveManualBindings() {
    return true; //[this.oil, this.liquid].map((el) => typeof el.binding === "number").includes(false) === false;
  }

  get isValid(): boolean {
    return [this.stopCriterion.isValid, this.prodTimeRatio.isValid, this.liquid.isValid, this.oil.isValid].includes(false) === false;
  }

  get isCorrectResult(): boolean {
    return [this.waterCut.isCorrectResult, this.liquid.isCorrectResult, this.oil.isCorrectResult].includes(false) === false;
  }

  readonly isCorrectionEnabledHolder = (value: boolean) =>
    runInAction(() => {
      this.isCorrectionEnabled = value;
    });

  readonly correctionValueHolder = (value: number | null) =>
    runInAction(() => {
      this.correctionValue = value;
    });

  readonly beginDateHolder = (date: Dayjs) =>
    runInAction(() => {
      this.beginDate = date;
    });

  boundsHolder(mode: ForecastMode) {
    return (val: DateDomain) =>
      runInAction(() => {
        const key = [...KNOWN_GROUPS[mode].items.values()][0] as keyof WellFactProduction["factProduction"];
        const data = this.fc.fact.data!.factProduction[key] as Array<null | number>;
        const a = nearest(val.min, data);
        const b = nearest(val.max, data);
        this[mode].factDomain = {
          min: Math.min(a, b),
          max: Math.max(a, b),
        };
      });
  }

  dotsToggleHolder = (index: number, mode: ForecastMode) => {
    return () =>
      runInAction(() => {
        const { emptyDots } = this[mode];
        emptyDots[emptyDots.has(index) ? "delete" : "add"](index);
      });
  };

  modeHolder = (value: TechMode) => {
    runInAction(() => {
      this.metricForCompute = (
        {
          liquidRate: "liquid",
          oilRate: "oil",
          waterCut: "waterCut",
        } as const
      )[value];
    });
  };

  get mode(): TechMode {
    return (
      {
        liquid: "liquidRate",
        oil: "oilRate",
        waterCut: "waterCut",
      } as const
    )[this.metricForCompute];
  }

  swapMode(mode: ForecastMode): ForecastMode {
    return FORECAST_MODES.filter((v) => v !== this.metricForCompute).find((v) => v !== mode)!;
  }

  techDump(mode: ForecastMode) {
    const modeDump = this[mode].dump;
    let declineType = "new";
    if (this.fc.group === "base") {
      declineType = "a" in modeDump ? "manual" : "ranges";
    }
    const isIntervalDegeneracy =
      isScalarDump(modeDump) && modeDump.fnType === "geometric_progression" && modeDump.a !== null && modeDump.a !== undefined;
    const steps = this.fc.fact.data?.factProduction?.steps;
    return {
      [{
        liquid: "liquidRateM3Decline",
        oil: "oilRateTDecline",
        waterCut: "waterCutVolDecline",
      }[mode]]: {
        ...(this[mode].method === "excel" ? modeDump : { fn: modeDump }),
        declineType,
        binding: this[mode].binding,
        ...conditionally(this.fc.group === "base", () => {
          const prod = this.fc.fact.data?.factProduction?.oilProdT;
          const step =
            Array.isArray(steps) && Array.isArray(prod) && steps.at((prod as any).findLastIndex((v: number | null) => v !== null && v > 0));
          return {
            intervalParams:
              isIntervalDegeneracy && Array.isArray(steps)
                ? {
                    startStep: step as number,
                    endStep: step as number,
                  }
                : this.fc.intervalParamsDump(mode),
          };
        }),
      },
    };
  }

  get dump() {
    const needCorrect = this.isCorrectionEnabled && this.correctionValue !== null;
    return {
      wellId: this.fc.wellId,
      stratumId: this.fc.stratumId,
      scenarioId: this.fc.scenarioId,
      ...conditionally(this.fc.gtmId !== null, { gtmId: this.fc.gtmId }),
      stopCriterion: this.stopCriterion.dump,
      prodTimeRatio: this.prodTimeRatio.dump,
      shifting: this.beginDate.endOf("month").diff(this.fc.manager.actualStateDate, "month") - 1,
      ...conditionally(this.metricForCompute !== "liquid", this.techDump("liquid")),
      ...conditionally(this.metricForCompute !== "oil", this.techDump("oil")),
      ...conditionally(this.metricForCompute !== "waterCut", this.techDump("waterCut")),
      needCorrect,
      ...conditionally(needCorrect, { oilRateTCorrectA: this.correctionValue }),
    };
  }

  methodSelector(mode: ForecastMode): DefaultOptionType[] {
    const isDebet = mode === "oil" || mode === "liquid";
    return [
      ...methodSelector(mode).map((option) => applyAssetsValidation(option, mode, this.fc.selection.recoverableResources.recoverableResourcesStart)),
      ...conditionallyArr(isDebet, {
        value: "wellsAnalog",
        label: "Скважины аналоги",
      }),
      ...conditionallyArr(isDebet, {
        value: "typical",
        label: "Типовая кривая",
      }),
      ...conditionallyArr(isDebet, {
        value: "excel",
        label: "Загрузка из excel",
      }),
    ];
  }

  get toSave(): SettingsSave {
    return {
      oil: this.oil.toSave,
      liquid: this.liquid.toSave,
      waterCut: this.waterCut.toSave,
      stopCriterion: this.stopCriterion.dump,
      prodTimeRatio: this.prodTimeRatio.prodTimeRatio,
      metricForCompute: this.metricForCompute,
      beginDate: this.beginDate.toString(),
      isCorrectionEnabled: this.isCorrectionEnabled,
      correctionValue: this.correctionValue,
    };
  }

  fromSave({ oil, liquid, waterCut, stopCriterion, prodTimeRatio, metricForCompute, beginDate, isCorrectionEnabled, correctionValue }: SettingsSave) {
    transaction(() => {
      this.oil.fromSave(oil);
      this.liquid.fromSave(liquid);
      this.waterCut.fromSave(waterCut);
      this.stopCriterion.applyDump(stopCriterion);
      this.prodTimeRatio.prodTimeRatio = prodTimeRatio;
      this.metricForCompute = metricForCompute;
      this.beginDate = dayjs(beginDate);
      this.isCorrectionEnabled = isCorrectionEnabled;
      this.correctionValue = correctionValue;
    });
  }

  async save() {
    await this.fc.manager.updateSingleSetting({
      ...this.fc.key,
      data: this.toSave,
    });
  }

  get saved(): SettingsSave | null | undefined {
    return this.fc.manager.getSettingsSave(this.fc.key);
  }

  get isSaved(): boolean {
    return hash(this.saved ?? {}) === hash(this.toSave);
  }
}

export { type DateDomain, type SettingsSave, WellTechForecastSettings };
