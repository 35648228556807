import { DefaultOptionType } from "antd/es/select";
import dayJS, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { ExternalFilterOption, TreeFilter } from "elements/tree/model/filters/filters";
import { Nest, Tree as TreeRoot } from "elements/tree/model/tree";
import { global } from "models/global";
import { User } from "models/user";
import { ProjectNewRaw } from "services/back/project";
import { ProjectParticipantInfo } from "services/back/projectParticipants";
import { Well } from "services/back/wells";

const NESTING_FIELDS: Nest<Well, string>[] = [
  {
    key: "field",
    title: "Месторождение",
    getter: (well: Well) => well.mine.field.title,
  },
  {
    key: "par1",
    title: "Куст",
    getter: ({ mine: { title } }: Well) => title,
  },
];

type Participant = {
  user: ProjectParticipantInfo;
  roleId: number;
};

class CreateProject {
  private licenseRegions = new Set<string>();
  private filters: TreeFilter<Well, any>[];

  public currentTppId = global.tpp.first!.id;
  public lastFactMonth = dayJS();
  public lastForecastYear = 2054;
  public title = "Новый проект";
  public description = "";
  public externalFilters: Record<string, ExternalFilterOption<Well>> = {
    lastFact: new ExternalFilterOption(({ date }: Well) => dayJS(date) < this.lastFactMonth),
  };
  public readonly tree: TreeRoot<Well>;
  public participants: Participant[] = [];

  constructor(public readonly creator: User) {
    this.wells.forEach(({ licenseZoneId }) => this.licenseRegions.add(this.licenseZoneTitle(licenseZoneId)));

    this.filters = [
      {
        title: "Лицензионные участки",
        options: Array.from(this.licenseRegions),
        predicateFactory:
          (selected) =>
          ({ licenseZoneId }: Well) =>
            selected.includes(this.licenseZoneTitle(licenseZoneId)),
      },
    ];
    this.tree = new TreeRoot(null, this.wells, NESTING_FIELDS, this.filters, true, this.externalFilters);
    this.tree.setSelectedLevels([0, 1]);

    makeObservable(this, {
      title: observable,
      description: observable,
      participants: observable,
      tree: observable,
      currentTppId: observable,
      lastFactMonth: observable,
      externalFilters: observable,
      setDescription: action,
      setTitle: action,
      setRole: action,
      setLastFactMonth: action,
      setLastForecastYear: action,
      isParticipantsOk: computed,
    });

    reaction(
      () => this.lastFactMonth,
      (newValue) => {
        this.externalFilters.lastFact.predicate = ({ date }: Well) => {
          if (newValue) {
            return dayJS(date) < newValue;
          }
          return true;
        };
      }
    );
  }

  get wells(): Well[] {
    if (global.wells.values) {
      return Array.from(global.wells.values);
    } else {
      return [];
    }
  }

  get isParticipantsOk() {
    return this.participants.length === 0 || this.participants.every((participant) => participant.roleId !== 0);
  }

  addParticipant = (user: ProjectParticipantInfo) => {
    this.participants.push({ user, roleId: 0 });
  };

  removeParticipant = (id: number) => {
    const index = this.participants.findIndex(({ user: { id: uId } }) => uId === id);
    this.participants.splice(index, 1);
  };

  setRole = (id: number, roleId: number) => {
    const index = this.participants.findIndex(({ user: { id: uId } }) => uId === id);
    this.participants[index].roleId = roleId;
  };

  setTitle = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    this.title = value;
  };

  setDescription = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.description = value;
  };

  licenseZoneTitle(id: number | null) {
    return global.licenseRegions.find(({ id: lId }) => lId === id)?.title ?? "";
  }

  setLastFactMonth = (date: Dayjs) => {
    this.lastFactMonth = date;
  };

  setLastForecastYear = (date: number) => {
    this.lastForecastYear = date;
  };

  get project(): ProjectNewRaw {
    return {
      title: this.title,
      description: this.description,
      fieldTitle: "Месторождение имени В.И.Некрасова",
      investStartYear: 2004,
      stop: this.lastForecastYear,
      actualStateDate: this.lastFactMonth.format("YYYY-MM-DD"),
      createdBy: this.creator.id,
      createdAt: new Date().toString(),
      wellsBaseNumber: 250,
    };
  }

  get tppAsSelectOptions(): DefaultOptionType[] {
    const options: DefaultOptionType[] = [];
    for (const { title, id, ngdo } of global.tpp.values!) {
      const ngdoIndex = options.findIndex(({ title }) => title === ngdo.title);
      if (ngdoIndex === -1) {
        options.push({
          label: ngdo.title,
          title: ngdo.title,
          options: [{ label: title, value: id }],
        });
      } else {
        options[ngdoIndex].options.push({ label: title, value: id });
      }
    }

    return options;
  }
}

export { CreateProject };
