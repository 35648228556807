import React from "react";
import { observer } from "mobx-react";

import { Notification } from "elements/notification/notification";
import { setupAccuracy } from "utils/round";

type ProcessNotificationProps = {
  process: any;
  processKey: string;
};

const titleMap = {
  save: "Сохранение данных",
  delete: "Удаление данных",
  update: "Обновление данных",
};

const textMap = {
  save: "загрузки сохраненных данных",
  delete: "удаления данных о добыче",
  update: "обновления данных",
};

const ProcessNotification: React.FC<ProcessNotificationProps> = observer(({ process, processKey }) => {
  const progressPercent = setupAccuracy((process.progress / process.amount) * 100);
  const processTitle = titleMap[processKey as keyof typeof titleMap];
  const processText = textMap[processKey as keyof typeof textMap];

  if (process && process.going) {
    return (
      <Notification key={processKey} title={processTitle} showProgress progress={progressPercent} type="info" closable={false}>
        Выполняется процесс {processText}. Пожалуйста, не закрывайте страницу до его завершения.
      </Notification>
    );
  }

  return null;
});

export { ProcessNotification };
