import { FC } from "react";
import { Button } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { DebugZone } from "elements/debugZone/debugZone";
import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Loader } from "elements/loader";
import type { EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";

import { WellsList } from "./wellsList/wellsList";
import { WellTechForecast } from "./wellTechForecast/wellTechForecast";
import { LoadScreen } from "./loadScreen";
import { useTechForecastModel } from "./useTechForecastModel";

import cn from "./techForecast.module.less";

const SELECT_PREDICATES = {
  base: (event: EventNode) => event.intervention === null && event.well.fond === "Base",
  new: (event: EventNode) => event.intervention === null && event.well.fond === "New",
  gtm: (event: EventNode) => event.intervention !== null,
  wellId: (wellId: number | Set<number>) => (event: EventNode) =>
    event.intervention === null && (wellId instanceof Set ? wellId.has(event.well.id) : event.well.id === wellId),
} as const;

const TechForecast: FC = observer(() => {
  const model = useTechForecastModel();

  if (model.selection.selectedEvents.length === 0) {
    return (
      <FullScreenEmpty>
        Перейдите во вкладку скважин и выберите те, для которых будет составляться прогноз
        <Button onClick={() => model.selection.selectByPredicate(SELECT_PREDICATES.base)}>Выбрать базовый фонд</Button>
        <Button onClick={() => model.selection.selectByPredicate(SELECT_PREDICATES.new)}>Выбрать новый фонд</Button>
        <Button onClick={() => model.selection.selectByPredicate(SELECT_PREDICATES.gtm)}>Выбрать ГТМ</Button>
        <DebugZone>
          <Button onClick={() => model.selection.selectByPredicate(SELECT_PREDICATES.wellId(1424))}>Выбрать скважину 2058</Button>
          <Button onClick={() => model.selection.selectByPredicate(SELECT_PREDICATES.wellId(1775))}>Выбрать скважину 1131</Button>
          <Button onClick={() => model.selection.selectByPredicate(SELECT_PREDICATES.wellId(new Set([1295, 1840, 1502, 1251, 1857, 1379])))}>
            Выбрать скважины 2075-2082
          </Button>
        </DebugZone>
      </FullScreenEmpty>
    );
  }
  return (
    <Loader wrapperClassName={cn.loader} spinning={model.isLoading} tip={<LoadScreen />}>
      <div className={cn.layout}>
        <PageFrameTitlePortal permissionSection="tech" model={model} onSave={model.save} submitting={model.isLoading} submitCustomTooltip={model} />
        <WellsList />
        <WellTechForecast />
      </div>
    </Loader>
  );
});

export { TechForecast };
