import { FC, ReactNode, useEffect, useMemo } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import { Tree } from "elements/tree/model/tree";
import { useSearchParamsStorage } from "elements/useSearchParamsStorage";
import { ProjectData, ProjectDataContext } from "models/project/context/projectContext";
import { Forecast, useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { useProject } from "models/project/project";
import { compressIds, decompressIds } from "utils/compresors";

import { usePagePostfix } from "./secondaryMenu/scenarioMenu";

const PROJECT_CONTEXT_QUERY_PARAMS = {
  WT_WELLS: "_wt_wells",
  WT_SELECTED_LEVELS: "_wt_levels",
  WT_SELECTED_FILTERS: "_wt_filters",
};

const isTreeFilterModified = ({
  groups,
  value,
}: {
  groups?:
    | Array<{
        title: string;
        items: {
          key: string;
          label: string;
        }[];
      }>
    | undefined;
  value?: string[][];
}): boolean => {
  if (value === undefined) {
    return false;
  }
  if (groups === undefined) {
    return false;
  }
  for (let i = 0; i < groups.length; ++i) {
    if (groups[i].items.length !== value[i].length) {
      return true;
    }
  }
  return false;
};

const processTreeSelect = (tree: Tree<any>, selectedIds: Set<number> | null) => {
  if (selectedIds !== null) {
    tree.selectManager.selected.replace(observable.set(Array.from(selectedIds, (v) => `${v}`)));
  }
};

const ProjectContextProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const searchParamsStorage = useSearchParamsStorage();
  const project = useProject();
  const forecast = useForecast();
  const pagePostfix = usePagePostfix();
  const pageIdentifier = pagePostfix && pagePostfix.join("-");

  const projectData = useMemo(() => {
    console.assert(project !== null && project !== undefined, "Project is null or undefined");
    return new ProjectData(project!, searchParamsStorage);
  }, [project, searchParamsStorage]);

  useEffect(() => {
    /// Подтягивание данных в дерево из квери параметров
    if (projectData.wellsTree.length !== 0) {
      const wellTreeSelectedLevels = projectData.searchParamsStorage.getItem(PROJECT_CONTEXT_QUERY_PARAMS.WT_SELECTED_LEVELS);
      if (wellTreeSelectedLevels && Array.isArray(wellTreeSelectedLevels)) {
        projectData.wellsTree.setSelectedLevels(wellTreeSelectedLevels);
      } else {
        projectData.wellsTree.setSelectedLevels([1, 2]);
      }

      const wellTreeSelectedFilters = projectData.searchParamsStorage.getItem(PROJECT_CONTEXT_QUERY_PARAMS.WT_SELECTED_FILTERS);
      if (wellTreeSelectedFilters && Array.isArray(wellTreeSelectedFilters)) {
        projectData.wellsTree.filterManager?.setSelected(wellTreeSelectedFilters);
      }

      // TODO: В идеале этот код перенести в самый вверх, до выставления уровней и фильтров
      const wellTreeIdsRaw = projectData.searchParamsStorage.getItem(PROJECT_CONTEXT_QUERY_PARAMS.WT_WELLS);
      if (wellTreeIdsRaw && typeof wellTreeIdsRaw === "string") {
        let wellTreeIds;
        if (wellTreeIdsRaw === "all") {
          wellTreeIds = null;
        } else if (wellTreeIdsRaw === "none") {
          wellTreeIds = new Set<number>();
        } else if (wellTreeIdsRaw.startsWith("exclude:")) {
          const allIds = projectData.wellsTree.items.map((e) => e.id);

          const toExclude = decompressIds(wellTreeIdsRaw.split(":").pop()!);
          wellTreeIds = new Set(allIds.filter((x) => !toExclude.includes(x)));
        } else {
          wellTreeIds = new Set(decompressIds(wellTreeIdsRaw));
        }
        processTreeSelect(projectData.wellsTree, wellTreeIds);
      }
    }
  }, [projectData, projectData.searchParamsStorage, projectData.wellsTree]);

  useEffect(() => {
    /// Смена сценария
    console.assert(forecast !== undefined, "Forecast is undefined");
    projectData.setForecast(forecast as null | Forecast);
  }, [forecast, projectData]);

  useEffect(() => {
    /// Перенос состояния в квери строку для выбранных уровней в дереве
    if (projectData.wellsTree.length !== 0) {
      projectData.searchParamsStorage.setItem(PROJECT_CONTEXT_QUERY_PARAMS.WT_SELECTED_LEVELS, projectData.wellsTree.selectedNests);
    }
  }, [projectData, projectData.searchParamsStorage, projectData.wellsTree.selectedNests, projectData.forecast]);

  useEffect(() => {
    /// Перенос состояния в квери строку для выбранных фильтров в дереве
    if (projectData.wellsTree.length !== 0) {
      if (isTreeFilterModified({ groups: projectData.wellsTree.filterManager?.groups, value: projectData.wellsTree.filterManager?.value })) {
        projectData.searchParamsStorage.setItem(PROJECT_CONTEXT_QUERY_PARAMS.WT_SELECTED_FILTERS, projectData.wellsTree.filterManager?.value);
      } else {
        projectData.searchParamsStorage.setItem(PROJECT_CONTEXT_QUERY_PARAMS.WT_SELECTED_FILTERS, "all");
      }
    }
  }, [projectData, projectData.searchParamsStorage, projectData.wellsTree.filterManager?.value, projectData.forecast, pageIdentifier]);

  useEffect(() => {
    /// Перенос состояния в квери строку для выбранных скважин в дереве
    if (projectData.wellsTree.length !== 0) {
      let wellTreeIds;
      if (projectData.wellsTree.selectManager?.isRootSelected) {
        wellTreeIds = "all";
      } else if (projectData.wellsTree.selectedItems.length === 0) {
        wellTreeIds = "none";
      } else if (projectData.wellsTree.items.length - projectData.wellsTree.selectedItems.length < projectData.wellsTree.items.length / 2) {
        const selectedIds = projectData.wellsTree.selectedNesting.filter((e) => e.item != null && e.item instanceof Well).map((e) => e.item!.id);
        const allIds = projectData.wellsTree.items.map((e) => e.id);
        const toExclude = allIds.filter((x) => !selectedIds.includes(x));

        wellTreeIds = "exclude:" + compressIds(toExclude);
      } else {
        wellTreeIds = compressIds(projectData.wellsTree.selectedItemsId.sort());
      }

      projectData.searchParamsStorage.setItem(PROJECT_CONTEXT_QUERY_PARAMS.WT_WELLS, wellTreeIds);
    }
  }, [projectData, projectData.searchParamsStorage, projectData.wellsTree.selectedItems, projectData.forecast, pageIdentifier]);

  return <ProjectDataContext.Provider value={projectData}>{children}</ProjectDataContext.Provider>;
});

export { ProjectContextProvider };
