import { TemplateCard } from "elements/importModalContent/templateInfoCards";

export const BOUNDARY_CONDITIONS_TEMPLATE_INFO_CARDS: TemplateCard[] = [
  {
    widthBasis: 235,
    sections: [
      {
        title: "Месяц",
        content:
          "Обязательно для заполнения.\nУказываются месяцы в формате ММ.ГГГГ в пределах прогнозного периода сценария",
      },
    ],
  },
  {
    widthBasis: 380,
    sections: [
      {
        title: "Расход нефти и расход жидкости",
        content:
          "Обязательно для заполнения. Указываются значения расходов продукции, поступающей от внешнего источника, в соответсвующие месяцы прогнозного периода",
      },
    ],
  },
];
