import { createContext, useContext } from "react";
import { makeAutoObservable, reaction } from "mobx";

import { Tree } from "elements/tree/model/tree";
import { SearchParamsStorage } from "elements/useSearchParamsStorage";
import { Lazy } from "models/lazy";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";

import { Project } from "../project";

import * as scenarioComparison from "./scenarioComparison";
import * as wellsTree from "./wellsTree";

class ProjectData {
  public forecast: Forecast | null = null;
  public wellsTree: Tree<Well> = wellsTree.generateEmptyWellTree();
  private readonly scenarioComparisonState = new Lazy(() => new scenarioComparison.ScenarioComparison(this.project));
  constructor(public project: Project, public searchParamsStorage: SearchParamsStorage) {
    makeAutoObservable(this);

    wellsTree.onUpdateForecast(this, null);

    reaction(
      () => this.forecast,
      () => {
        wellsTree.onUpdateForecast(this, this.forecast);
      }
    );
    reaction(
      () => this.forecast?.wells.allWells,
      () => {
        wellsTree.onUpdateForecast(this, this.forecast);
      }
    );
  }

  public setForecast(forecast: Forecast | null) {
    this.forecast = forecast;
  }

  get scenarioComparison() {
    return this.scenarioComparisonState.value;
  }
}

const ProjectDataContext = createContext<ProjectData | null>(null);

const useProjectContext = (): ProjectData => {
  const projectData = useContext(ProjectDataContext);
  console.assert(projectData != null, "Запрошен projectData вне контекста ProjectDataContext");
  return projectData!;
};

export { ProjectData, ProjectDataContext, useProjectContext };
