import { UOMObject } from "elements/uom";
import { Forecast } from "models/project/fact/forecast/forecast";
import { StorageKey } from "services/finance/utils";
import { zipLongest } from "utils/itertools";
import { req as request } from "utils/request";

import { extractUSCModels } from "./calculate/ecy";
import { operatingSchema } from "./calculate/operating";
import { BackendStorageMock } from "./backendStorage";

type CostsDescription = {
  id: number;
  title: string;
  group: string | null;
  primary: UOMObject;
  secondary: UOMObject | null;
  is_operations: boolean;
  is_net: boolean;
};

type CostParamTable = {
  base_index: Array<number>;
  net: Array<number>;
  real: Array<number>;
};

type CostsParamsList = {
  costs: CostsDescription[];
};

type ValueDump = {
  primary: number | (number | null)[] | null;
  secondary: number | (number | null)[] | null;
  vector: Array<number | null>;
  inputMethod?: "primary" | "secondary";
};

type CostDump = {
  value: ValueDump | null;
  description: CostsDescription;
};

type TotalDump = {
  id: number;
  primary?: number;
  secondary?: number;
  net?: Array<number>;
  operations?: Array<number>;
};

const getCostParams = async (): Promise<CostsDescription[]> => {
  return (await request.get<CostsParamsList>("costs/")).costs;
};

const getPerUnit = async (forecast: Forecast, costId: number) => {
  const result = await request.post<number>(`costs/per-unit`, {
    id: costId,
    scenario_id: forecast.id,
    schema_operations: forecast.licenseRegions.ids.map((lrId) => operatingSchema(forecast, lrId)),
  });
  return result;
};

const getTotal = async (forecast: Forecast, param: TotalDump): Promise<CostParamTable> => {
  const result = await request.post<CostParamTable>(`costs/total`, {
    ...param,
    scenario_id: forecast.id,
    schema_usc: extractUSCModels(forecast)[0].schema_usc,
  });
  return result;
};

const costsSource = (storageNameSuffix: StorageKey) => {
  const [getCostsValues, setCostsValues] = new BackendStorageMock<void, { data: ValueDump[] }, { data: ValueDump[] }>(
    `costs`,
    storageNameSuffix.fact,
    storageNameSuffix.forecast,
    false
  ).decorators;
  return [
    async (): Promise<CostDump[]> =>
      Promise.all([getCostsValues(async () => ({ data: [] }))().then(({ data }) => data), getCostParams()]).then(
        ([values, descriptions]: [ValueDump[], CostsDescription[]]) =>
          [...zipLongest(values, descriptions)].map(
            ([value = null, description]): CostDump => ({ value, description: description! })
          )
      ),
    (data: CostDump[]) =>
      setCostsValues(async (v) => v)({ data: data.map(({ value }) => value!) }).then((val) =>
        data.map(({ description }, id) => ({
          value: val.data[id],
          description,
        }))
      ),
  ] as const;
};

export {
  type CostDump,
  type CostParamTable,
  type CostsDescription,
  costsSource,
  getPerUnit,
  getTotal,
  type TotalDump,
  type ValueDump,
};
