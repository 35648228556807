import { type FC } from "react";
import type { RouteObject } from "react-router-dom";
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AuthorizedOutline } from "routing/outlines/authorized/authorizedOutline";
import { ForecastOutline } from "routing/outlines/forecastOutline";

import { FullScreenEmpty, FullScreenLoader } from "elements/fullScreen/fullScreen";
import { ErrorBoundary } from "features/errorBoundary/errorBoundary";
import { global } from "models/global";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { useSession } from "models/session";
import { Dashboard } from "pages/dashboard";
import { Projects } from "pages/projects";
import { SystemSetup } from "pages/systemSetup";
import { TableDebug } from "pages/tableDebug";
import { Tree } from "pages/tree";
import { Users } from "pages/users";
import { VersionFem } from "pages/versionFem";
import { conditionally, conditionallyArr } from "utils/conditionally";

import { COMPARE, DEFAULT_PROJECT_PAGE, GENERAL, MANAGEMENT, REPORTS, SCENARIO } from "./outlines/authorized/assets/routing";
import { ProjectOutline } from "./outlines/projectOutline";

import "@okopok/components/style.css";

const useMainRouterParams = (): { project?: number; scenario?: number; tab?: number } => {
  const params = useParams<"project" | "scenario">();
  const project = parseInt(params.project ?? "", 10);
  const scenario = parseInt(params.scenario ?? "", 10);
  return {
    ...conditionally(isFinite(project), { project }),
    ...conditionally(isFinite(scenario), { scenario }),
  };
};

const isNumber = (str: string | undefined) => str === `${parseInt(str ?? "", 10)}`;

const DefaultResolver: FC = observer(() => {
  const params = useParams();
  const project = useProject();
  const { projects } = useSession();
  const forecast = useForecast();
  if (project === undefined) {
    return <FullScreenLoader>Загрузка информации о месторождениях</FullScreenLoader>;
  }
  if (project === null) {
    if ("project" in params) {
      if (isNumber(params.project)) {
        return (
          <FullScreenEmpty>
            Не удалось найти проект с ключом {params.project} (известны {Array.from(projects!.entries!, ([key]) => key).join(", ")})
          </FullScreenEmpty>
        );
      }
      return <FullScreenEmpty>Не найдена страница {params.project}</FullScreenEmpty>;
    }
    return <Navigate replace to="/" />;
  }
  if (forecast === undefined) {
    return <FullScreenLoader>Загрузка информации о фактических данных</FullScreenLoader>;
  }
  if (forecast === null) {
    if ("scenario" in params) {
      if (isNumber(params.scenario)) {
        return (
          <FullScreenEmpty>
            Не удалось найти набор фактических данных с ключом {params.scenario} (известны {project.fact.forecasts.map(({ id }) => id)!.join(", ")})
            для проекта {project.title}
          </FullScreenEmpty>
        );
      }
      return <FullScreenEmpty>Не найдена страница {params.scenario}</FullScreenEmpty>;
    }
    return <Navigate replace to={`/${project.id}/${DEFAULT_PROJECT_PAGE}`} />;
  }
  return <Outlet />;
});

const PROJECT_ROUTER: RouteObject[] = [COMPARE, GENERAL, MANAGEMENT, REPORTS, SCENARIO]
  .flat()
  .map((group) => {
    if ("children" in group) {
      return group.children.map(({ key, element }) => ({ path: key, element }));
    }
    return { path: group.key, element: group.element };
  })
  .flat()
  .map(({ path, ...other }) => [
    { path, ...other },
    { path: `${path}/*`, ...other },
  ])
  .flat();

const ADJUSTMENTS = {
  children: [
    ...PROJECT_ROUTER,
    {
      path: "",
      element: <DefaultResolver />,
    },
    {
      path: "*",
      element: <DefaultResolver />,
    },
  ],
};

/**
 * В целом логика такая: есть страницы, которые сразу в корне. На момент написания комента это только Projects
 * Далее, если не одна из таких страниц не подошла то фрагмент считается айдишником проекта. Если он спарсился
 * то идёт попытка обнаружить его в списке проектов, если проект удалось обнаружить то outline допускает отображение
 * страниц проекта. Если нет выпадает 404
 */
const router = createBrowserRouter([
  {
    // тут добавляются и боковое и вспомогательное меню, оно само вычисляет свою видимость в зависимости от выбранной вкладки
    element: <AuthorizedOutline />,
    children: [
      {
        element: <Projects />,
        path: "/",
      },
      {
        path: "tree",
        element: <Tree />,
      },
      {
        element: <SystemSetup />,
        path: "setup",
      },
      {
        element: <Users />,
        path: "users",
      },
      {
        element: <Dashboard />,
        path: "dashboard",
      },
      {
        element: <VersionFem />,
        path: "versionFem",
      },
      ...conditionallyArr(global.IS_DEBUG_ZONE, {
        element: <TableDebug />,
        path: "table",
      }),
      // если не выбрана ни одна из корневых страниц то первый сегмент считается айди проекта
      {
        path: ":project",
        // тут визуал не добавляется, просто проверяется корректность ввода айдишника
        element: <ProjectOutline />,
        errorElement: <ErrorBoundary />,
        children: [
          ...PROJECT_ROUTER,
          // если страница не найдена то второй сегмент считается айди сценария
          {
            path: "*",
            children: [
              ...PROJECT_ROUTER,
              {
                path: ":scenario",
                // тут проверяется, что с айди сценария порядок
                element: <ForecastOutline />,
                ...ADJUSTMENTS,
              },
              {
                path: "",
                ...ADJUSTMENTS,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const AuthorizedRouter = () => <RouterProvider router={router} fallbackElement={<FullScreenLoader />} />;

export { AuthorizedRouter, useMainRouterParams };
