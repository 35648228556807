import { FC, ReactNode, useCallback, useEffect } from "react";
import { Button, Progress, Space } from "antd";
import { NotificationPlacements } from "antd/es/notification/interface";
import useNotification from "antd/es/notification/useNotification";

type Props = {
  notifyKey?: string;
  title?: ReactNode;
  placement?: (typeof NotificationPlacements)[number];
  children?: ReactNode;
  buttons?: ReactNode;
  duration?: number | null;
  onClose?: VoidFunction;
  showProgress?: boolean;
  progress?: number;
  closable?: boolean;
  type?: "info";
};

const Notification: FC<Props> = ({
  notifyKey = "alert",
  title,
  placement = "bottomRight",
  children,
  buttons,
  duration = null,
  onClose,
  showProgress,
  progress,
  closable = true,
  type,
}) => {
  const [api, contextHolder] = useNotification();
  const openApi = type ? api[type] : api.open;

  const handleClose = useCallback(() => {
    api.destroy(notifyKey);
    onClose?.();
  }, [onClose, api, notifyKey]);

  const openNotification = useCallback(() => {
    openApi({
      message: title ?? <></>,
      placement: placement,
      description: (
        <>
          {children ?? ""}
          {showProgress && <Progress percent={progress} status="active" showInfo={false} style={{ marginTop: 10 }} />}
        </>
      ),
      btn: closable ? (
        <Space>
          {buttons}
          <Button type="primary" size="small" onClick={handleClose}>
            Закрыть
          </Button>
        </Space>
      ) : null,
      key: notifyKey,
      duration: duration,
      onClose: handleClose,
      showProgress,
      closable,
    });
  }, [openApi, title, placement, children, showProgress, progress, closable, buttons, handleClose, notifyKey, duration]);

  useEffect(() => {
    children && openNotification();
  }, [openNotification, children]);

  return <>{contextHolder}</>;
};

export { Notification };
