import { computed, makeObservable, observable, runInAction } from "mobx";

import { getTechForecast, type WellTechProduction } from "services/back/techForecast/request";
import { getRandomUid } from "utils/random";

import type { WellTechForecast } from "./wellTechForecast";

class WellTechForecastResult {
  #data = observable.box<null | undefined | WellTechProduction>();
  readonly uuid = getRandomUid();
  public wasShown: boolean = false;
  public isSubmitted: boolean = false;

  constructor(dump: Parameters<typeof getTechForecast>[0], private fc: WellTechForecast) {
    getTechForecast(dump)
      .then((result) =>
        runInAction(() => {
          if (result.length === 0) {
            this.#data.set(null);
          }
          this.#data.set(result[0]);
        })
      )
      .catch((problem: unknown) => {
        runInAction(() => {
          this.#data.set(null);
        });
        if (problem instanceof Response) {
          problem.json().then((_: unknown) => {
            // @todo обработка ошибки запроса
          });
        }
      });

    makeObservable(this, {
      data: computed,
      isLoading: computed,
      isNull: computed,
      wasShown: observable,
      isSubmitted: observable,
    });
  }

  setShown = () =>
    runInAction(() => {
      this.wasShown = true;
    });

  get data() {
    return this.#data.get();
  }

  get isLoading() {
    return this.data === undefined;
  }

  get isCorrect() {
    const fc = this.data?.forecastProduction;
    if (fc === undefined) {
      return true;
    }
    return fc.isCorrectLiquidRateM3 && fc.isCorrectOilRateT && fc.isCorrectWaterCutVol;
  }

  get isNull() {
    if (this.data === null) {
      return true;
    }

    if (this.data === undefined) {
      return false;
    }

    //если liquidRateT и oilRateT равно нулю, то это считается неудавшимся прогнозом
    const { factProduction } = this.data;
    const isOilRateNull = factProduction?.oilRateT.at(-1) === 0 || factProduction?.oilRateT.at(-1) === null;
    const isLiquidRateNull = factProduction?.liquidRateT.at(-1) === 0 || factProduction?.liquidRateT.at(-1) === null;

    if (isOilRateNull && isLiquidRateNull && !this.fc.settings.isHaveManualBindings) {
      return true;
    }

    // если все массивы (включая обводнённость) длинной в один элемент и дебиты нулевые а обводнённость null то это считается неудавшимся прогнозом
    const waterCut = this.data.forecastProduction.waterCutVol;
    const [waterCutFirst] = waterCut;
    return waterCut.length <= 1 && (waterCutFirst === null || waterCutFirst === undefined);
  }
}

export { WellTechForecastResult };
