import type { ReactNode } from "react";
import { Expand } from "@okopok/components/Table";

import { cartesianProduct } from "utils/itertools";

import type { SelectionManager } from "./selectionManager";

const KEY_JOIN_SYMBOL = "/";

type Title = string | ((item: Required) => ReactNode);
type Required = { id: number; title: Title };
type Nest<Leaf, T = unknown> = {
  // имя в селекторе
  title: string;
  // Значение в селекторе
  key: string;
} & (
  | {
      /* тут надоело типизировать. Идея в том, что можно использовать всё что угодно в качестве ключа карзины.
  Если прилетает массив то Leaf кладётся во все карзины, содержащиеся в массиве */
      getter: (item: Leaf) => T;
      // а это то, что нужно писать в узел
      render: (value: T) => ReactNode;
      renderKey?: (value: T) => string;
    }
  | {
      getter: (item: Leaf) => string | string[];
    }
);

type TreeNodeBoth = {
  key: string;
  level: number;
  count: number;
};

type Nested<Leaf> = {
  item: Leaf;
  nest: Nest<Leaf>;
  bins: Array<{
    key: string;
    data: unknown;
    title: ReactNode;
  }>;
};

type TreeNodeLeaf<Leaf> = TreeNodeBoth & {
  // все пути по которым может быть заспличен элемент со всеми вариантами ключей
  nests: Nested<Leaf>[];
  // по которым фактически заспличен
  splits: string[];
  data: Leaf;
  id: number;
};

type TreeNodeNest<Leaf> = TreeNodeBoth & {
  children: TreeNode<Leaf>[];
  data: unknown;
  element: ReactNode;
};

function isLeafNode<Leaf extends Required>(node: TreeNode<Leaf>): node is TreeNodeLeaf<Leaf> {
  return "nests" in node;
}

function isNestNode<Leaf extends Required>(node: TreeNode<Leaf>): node is TreeNodeNest<Leaf> {
  return "children" in node;
}

const nestingKeys = (keys: string[][], id: number) =>
  Array.from(cartesianProduct(...keys), (str) => [...str.sort(), id].join(KEY_JOIN_SYMBOL));

function selectionKeys<Leaf extends Required>({ nests, key, id, splits }: TreeNodeLeaf<Leaf>): string[] {
  if (nests.length === 0) {
    return [key];
  }
  const keys = nests.map(({ bins }) => {
    const binKeys = new Set(bins.map(({ key }) => key));
    // если мы находимся в ветке по этому несту (по нему есть сплит)
    const branch = splits.find((s) => binKeys.has(s));
    if (branch) {
      // то нас интересует только ветка, по которой мы пошли
      return [branch];
    }
    return [...binKeys.keys()];
  });
  return nestingKeys(keys, id);
}

function itemPaths<Leaf extends Required>(nesting: Nested<Leaf>[], id: number) {
  if (nesting.length === 0) {
    return [
      {
        path: [],
        key: `${id}`,
      },
    ];
  }
  return Array.from(cartesianProduct(...nesting.map((nested) => nested.bins)), (path) => ({
    path,
    key: [...path.map(({ key }) => key).sort(), id].join(KEY_JOIN_SYMBOL),
  }));
}

const keyInference = (v: unknown): string => {
  if (typeof v === "string") {
    return v;
  }
  if (typeof v === "number") {
    return `${v}`;
  }
  if (typeof v !== "object" || v === null) {
    console.warn("ошибка вычисления ключа", v);
    return "error";
  }
  if ("id" in v && (typeof v.id == "string" || typeof v.id === "number")) {
    return `${v.id}`;
  }
  if ("key" in v && (typeof v.key == "string" || typeof v.key === "number")) {
    return `${v.key}`;
  }
  console.warn("ошибка вычисления ключа", v);
  return "error";
};

function nestKey<Leaf extends Required>(nest: Nest<Leaf>, nestValue: unknown): string {
  return "renderKey" in nest && typeof nest.renderKey === "function"
    ? nest.renderKey(nestValue)
    : keyInference(nestValue);
}

type TreeNode<Leaf> = TreeNodeLeaf<Leaf> | TreeNodeNest<Leaf>;

type TreeListNode<Leaf> = TreeNode<Leaf> & {
  parent: null | TreeNode<Leaf>;
  expand: Expand;
  selectionManager: SelectionManager<any>;
};

export { isLeafNode, isNestNode, itemPaths, KEY_JOIN_SYMBOL, nestKey, selectionKeys };
export type { Nest, Nested, Required, Title, TreeListNode, TreeNode, TreeNodeLeaf, TreeNodeNest };
