import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Checkbox, DatePicker, Dropdown, MenuProps, Popconfirm, Select, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import locale from "antd/es/date-picker/locale/ru_RU";
import classNames from "classnames";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react";

import { AlertPopover } from "elements/alertPopover/alertPopover";
import { Icon } from "elements/icon/icon";
import { useForecast } from "models/project/fact/forecast/forecast";

import { ReactComponent as ArrowDownIcon } from "./arrowDown.svg";
import { ReactComponent as ErrorsIcon } from "./errors.svg";
import { ReactComponent as ErrorsDisabledIcon } from "./errorsDisabled.svg";

import cn from "./calculateButton.module.less";

type PeriodDaysType = [Dayjs, Dayjs] | undefined;

const { RangePicker } = DatePicker;

const calculateItems = [
  { key: "hydraulic", label: "Гидравлический расчет" },
  { key: "economic", label: "Экономический расчет" },
];

const CalculateButton: FC<{ fullWidth?: boolean }> = observer(({ fullWidth }) => {
  const [ecyId, setEcyId] = useState(-1);
  const [isDefault, setIsDefault] = useState(true);
  const [period, setPeriod] = useState<PeriodDaysType>();

  const forecast = useForecast()!;
  const { infrastructureValidator } = forecast.infrastructure;
  const { isCalculation, calculateHydraulic, calculateEconomic } = forecast.infrastructure.calculateStore;
  const loading = forecast.production.isLoading || isCalculation;

  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const alertIcon = infrastructureValidator.validationErrors.length ? <ErrorsIcon /> : <ErrorsDisabledIcon />;

  const onCalculate: MenuProps["onClick"] = (e) => {
    if (e.key === "hydraulic") {
      calculateHydraulic();
    } else {
      calculateEconomic(ecyId);
    }
  };

  const handleSelect = (id: number) => {
    setEcyId(id);
  };

  const handleCheck = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setIsDefault(checked);
    checked && setPeriod(undefined);
  };

  if (path === "table") {
    return (
      <div className={cn.wrapper}>
        <AlertPopover
          messages={infrastructureValidator.validationErrors}
          btnIcon={<Icon width="20px" height="20px" viewBox="0 0 20 20" content={alertIcon} />}
        />
        <Tooltip title={infrastructureValidator.validationTooltip}>
          <div>
            <Popconfirm
              title="Выберите период гидравлического расчета"
              icon={false}
              description={
                <div className={cn.popConfirmDescription}>
                  <Checkbox checked={isDefault} onChange={handleCheck}>
                    По умолчанию
                  </Checkbox>
                  <RangePicker
                    value={period}
                    format="MM.YYYY"
                    picker="month"
                    disabled={isDefault}
                    onChange={(v) => setPeriod((v ?? undefined) as PeriodDaysType)}
                    locale={locale}
                  />
                </div>
              }
              cancelText="Отмена"
              okText="Рассчитать"
              onConfirm={() => calculateHydraulic(true, period)}
            >
              <Button
                loading={loading}
                disabled={!!infrastructureValidator.validationTooltip || loading}
                type="primary"
                className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}
              >
                Рассчитать
              </Button>
            </Popconfirm>
          </div>
        </Tooltip>
      </div>
    );
  }

  if (path === "aggregation") {
    return (
      <div className={cn.wrapper}>
        <Select value={ecyId} onChange={handleSelect} options={forecast.ecyStore.selectItems} />
        <Tooltip title={infrastructureValidator.validationTooltip}>
          <Button
            onClick={() => calculateEconomic(ecyId)}
            loading={loading}
            disabled={!!infrastructureValidator.validationTooltip || loading}
            type="primary"
            className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}
          >
            Рассчитать
          </Button>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={cn.wrapper}>
      <Select value={ecyId} onChange={handleSelect} options={forecast.ecyStore.selectItems} />
      <AlertPopover
        messages={infrastructureValidator.validationErrors}
        btnIcon={<Icon width="20px" height="20px" viewBox="0 0 20 20" content={alertIcon} />}
      />
      <Tooltip title={infrastructureValidator.validationTooltip}>
        <div>
          <Dropdown
            disabled={infrastructureValidator.cannotCalculate || loading}
            menu={{ items: infrastructureValidator.cannotCalculate || loading ? [] : calculateItems, onClick: onCalculate }}
          >
            <Button loading={loading} type="primary" className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}>
              Рассчитать <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ArrowDownIcon />} />
            </Button>
          </Dropdown>
        </div>
      </Tooltip>
    </div>
  );
});

export { CalculateButton };
