import { action, computed, makeObservable, observable, runInAction, transaction } from "mobx";

import { SettingsSave } from "features/techForecast/models/well/wellTechForecastSettings";
import { deleteTechForecast } from "services/back/techForecast/request";
import {
  deleteTechParameters,
  getTechParameters,
  TechForecastSettings as TFSettings,
  updateTechParameters,
} from "services/back/techForecast/settings";

import { Forecast } from "../forecast";

type Key = Pick<TFSettings<SettingsSave>, "wellId" | "gtmId" | "stratumId">;

type KeyValidated = Pick<Key, "wellId" | "gtmId"> & {
  stratumId: number;
};

class TechForecastSettings {
  #updating = observable.set<string>();
  #settings = observable.map<string, SettingsSave>();
  private isLoadingValue: boolean = true;

  constructor(private readonly forecast: Forecast) {
    getTechParameters<SettingsSave>(forecast.id).then((settings) => {
      this.applySettingsSave(settings);
      runInAction(() => (this.isLoadingValue = false));
    });

    makeObservable<TechForecastSettings, "isLoadingValue" | "applySettingsSave">(this, {
      isLoadingValue: observable,
      isLoading: computed,
      update: action,
      delete: action,
      applySettingsSave: action,
    });
  }

  public get isLoading(): boolean {
    return this.isLoadingValue;
  }

  public get(key: Key): SettingsSave | null | undefined {
    if (!TechForecastSettings.isValidKey(key)) {
      return undefined;
    }
    const keyValidated = TechForecastSettings.key(key);

    if (this.isLoading || this.#updating.has(keyValidated)) {
      return undefined;
    }

    return this.#settings.get(keyValidated) ?? null;
  }

  public async update(key: Key, data: SettingsSave): Promise<void> {
    if (!TechForecastSettings.isValidKey(key)) {
      return;
    }

    const keyValidated = TechForecastSettings.key(key);
    this.#updating.add(keyValidated);

    const toSave = {
      ...key,
      scenarioId: this.forecast.id,
      data,
    };

    try {
      const result = await updateTechParameters<SettingsSave>([toSave]);
      this.applySettingsSave(result);
    } catch {}

    this.#updating.delete(keyValidated);
  }

  public async delete(keys: Key[], process: string): Promise<void> {
    const keysValidated = keys.filter(TechForecastSettings.isValidKey);
    transaction(() => {
      // `this.#updating.replace` should not work, because set probably has some elements (from `update`)
      keysValidated.forEach((key) => {
        this.#updating.add(TechForecastSettings.key(key));
      });
    });
    await deleteTechParameters(keysValidated.map((key) => ({ ...key, scenarioId: this.forecast.id }))).then(
      (deleted) => {
        transaction(() =>
          deleted.forEach((indexes) => {
            if (!TechForecastSettings.isValidKey(indexes)) {
              return;
            }
            this.#settings.delete(TechForecastSettings.key(indexes));
          })
        );
      }
    );
    transaction(() => {
      keysValidated.forEach((key) => {
        this.#updating.delete(TechForecastSettings.key(key));
      });
    });
    await deleteTechForecast(keysValidated.map((key) => ({ ...key, scenarioId: this.forecast.id })));
    await this.updateProduction(keysValidated, process);
  }

  public async updateProduction(keys: Key[], process?: string): Promise<void> {
    const uniqueEvents = new Set<string>();
    for (const { wellId, gtmId } of keys) {
      uniqueEvents.add(TechForecastSettings.key({ wellId, gtmId, stratumId: -1 }));
    }
    const toUpdate = Array.from(uniqueEvents, (encoded) => {
      const { wellId, gtmId } = TechForecastSettings.parseKey(encoded)!;
      return { wellId, gtmId: gtmId ?? undefined };
    });

    const updatePromises = toUpdate.map((updateData) => this.forecast.production.update([updateData]));

    await (process
      ? this.forecast.fact.project.evalSequentialRequests(updatePromises, process)
      : this.forecast.production.update(toUpdate));
  }

  private applySettingsSave = (settings: TFSettings<SettingsSave>[]) => {
    transaction(() => {
      for (const { data, ...indexes } of settings) {
        if (!TechForecastSettings.isValidKey(indexes)) {
          continue;
        }
        const key = TechForecastSettings.key(indexes);
        this.#settings.set(key, data);
      }
    });
  };

  private static key({ wellId, gtmId, stratumId }: KeyValidated): string {
    return [wellId, gtmId, stratumId].join(";");
  }

  private static parseKey(encoded: string): KeyValidated | undefined {
    const [wellId, gtmId, stratumId] = encoded.split(";").map((s) => (s !== "" ? parseInt(s) : null));
    const key = { wellId, gtmId, stratumId };
    if (TechForecastSettings.isValidKey(key)) {
      return key;
    }
    return undefined;
  }

  private static isValidKey(key: Record<keyof Key, number | null>): key is KeyValidated {
    if (key.stratumId === null) {
      console.error(`got stratumId = null. wellId=${key.wellId} gtmId=${key.gtmId}`);
      return false;
    }
    return true;
  }
}

export { TechForecastSettings };
